<template>
  <LoaderOverlay v-if="loading"/>
  <router-view />
</template>

<script>
import LoaderOverlay from "@/components/LoaderOverlay.vue";

export default {
  components:{
    LoaderOverlay
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading
    }
  },
}
</script>

