<template>
  <div>
    <LoaderOverlay v-if="loading" />
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">
      <div class="container" data-layout="container">
        <div class="row flex-center min-vh-100 py-6">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div class="card">
              <div class="card-header">
                <a class="d-flex flex-center" href="https://bulla.co.ke">
                  <img
                    class="me-2"
                    src="assets/img/icons/spot-illustrations/edms.png"
                    alt=""
                    height="48"
                  />
                </a>
                <!-- <span class="font-sans-serif fs-2 d-flex flex-center">Restautant</span> -->
              </div>

              <div class="position-relative">
                <hr />
                <div class="divider-content-center">
                  <h5>Log in</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <div class="btn-group">
                      <div class="dropdown font-sans-serif d-inline-block">
                        <a
                          class="dropdown-toggle"
                          id="dropdownMenuButton"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          ><i class="far fa-question-circle mt-1"></i
                        ></a>
                        <div
                          class="dropdown-menu"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div
                            class="scrollbar list"
                            style="max-height: 250px; width: 270px"
                          >
                            <h6
                              class="dropdown-header fw-bold text-uppercase px-card fs--2 pt-0 pb-2"
                            >
                              Select users
                            </h6>
                            <div class="dropdown-divider"></div>
                            <a
                              v-for="(user, i) in users"
                              :key="i"
                              class="dropdown-item"
                              data-toggle="dropdown"
                              @click="setUser(user.email)"
                              >{{ user.FIRST_NAME }} {{ user.LAST_NAME }}</a
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </span>
                  <input
                    class="form-control"
                    type="email"
                    v-model="formData.email"
                    placeholder="Email/Username"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                  />
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fas fa-unlock-alt me-2 ms-2 text-primary"></i>
                  </span>
                  <input
                    v-model="formData.password"
                    class="form-control input-lg"
                    type="password"
                    placeholder="Password"
                    aria-label="Username"
                    aria-describedby="basic-addon1"
                    v-on:keydown.enter="handleLogin"
                  />
                </div>
                <div class="row flex-between-center">
                  <div class="col-auto">
                    <div class="form-check mb-0">
                      <input
                        v-model="formData.remember_me"
                        class="form-check-input"
                        type="checkbox"
                        id="basic-checkbox"
                        checked="checked"
                      /><label
                        class="form-check-label mb-0"
                        for="basic-checkbox"
                        >Remember me</label
                      >
                    </div>
                  </div>
                 
                </div>
                <div class="mb-3 d-flex">
                  
                  <div class="col-auto mt-4 ms-1">
                    <a role="button" class="fs--1" @click="gotToForgotPassword"
                      >Forgot Password?</a
                    >
                  </div>

                  <button
                    class="btn btn-primary w-20 mt-3 ms-auto"
                    @click="handleLogin"
                  >
                    Log in
                    <i class="fa-regular fa-right-to-bracket"></i>
                  </button>
                </div>
                <div class="position-relative mt-4">
                  <hr />
                  <div class="divider-content-center">
                    <span class="mb-0 undefined me-1">or</span>
                    <span><a href="register.html">Create an account</a></span>
                  </div>
                </div>
                <div class="row g-2 mt-2">
                  <span class="font-sans-serif fs--1 d-flex flex-center"
                    >EDMS System |
                    <a class="mb-0 text-500">Version {{ version }}</a></span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
  </div>
</template>

<script>
import UserService from "../services/user.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  name: "LoginView",
  components: {
    LoaderOverlay,
  },
  data() {
    return {
      formData: {},
      users: [],
      version: process.env.VUE_APP_VERSION,
    };
  },
  created() {
    this.getLoginUsers();
  },
  mounted() {
    // Theme setup custome to bellalisa
    localStorage.setItem("navbarStyle", "card");
    localStorage.setItem("isFluid", true);
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    setUser(email) {
      console.log(email);
      this.username_key = email;
      this.formData.email = email;
    },
    gotToRegister() {
      this.$router.push("/register");
    },
    gotToForgotPassword() {
      this.$router.push("/forgot-password");
    },
    getLoginUsers() {
      this.$store.commit("SET_LOADING", true);
      return UserService.getLoginUsers().then(
        (response) => {
          console.log(response.data);
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            this.users = response.data.data;
          } else {
            console.log(response.data.error);
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          toast.error(
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(),
            {
              autoClose: 3000,
            }
          );
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    handleLogin() {
      if (this.formData.email && this.formData.password) {
        this.$store.commit("SET_LOADING", true);
        this.$store.dispatch("auth/login", this.formData).then(
          (response) => {
            if (response.status == 200) {
              toast.success("Welcome aboard!", {
                autoClose: 3000,
              });
              if (response.data.user.is_admin == 1) {
                setTimeout(() => {
                  this.$router.push("/dashboard");
                }, 1500);
              } else {
                setTimeout(() => {
                  this.$router.push("/home");
                }, 1500);
              }
              this.$store.commit("SET_LOADING", false);
            }
          },
          (error) => {
            this.$store.commit("SET_LOADING", false);
            toast.error(
              error.response.data.message ||
                (error.response && error.response.data) ||
                error.mesage ||
                error.toString(),
              { autoClose: 3000 }
            );
            console.log(error);
          }
        );
      }
    },
  },
};
</script>
