<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />

      <div class="row mb-2">
        <div class="col ms-n2 pe-1">
          <div class="card bg-100 shadow-none border">
            <div class="row gx-0 flex-between-center">
              <div class="col-sm-auto d-flex align-items-center">
                <img
                  class="ms-n2"
                  src="assets/img/illustrations/crm-bar-chart.png"
                  alt=""
                  width="90"
                />
                <div>
                  <h6 class="text-primary fs--1 mb-0">Welcome to</h6>
                  <h4 class="text-primary fw-bold mb-0">
                    Bulla <span class="text-info fw-medium">BI & POS</span>
                  </h4>
                </div>
                <img
                  class="ms-n4 d-md-none d-lg-block"
                  src="assets/img/illustrations/crm-line-chart.png"
                  alt=""
                  width="150"
                />
              </div>
              <div class="col-md-auto p-3">
                <form class="row align-items-center g-3">
                  <!-- <div class="col-auto">
                    <h6 class="text-700 mb-0">More actions:</h6>
                  </div> -->
                  <div class="col-auto">
                    <div class="btn-group mb-2 mb-md-0">
                      <button
                        class="bg-soft-success nav-link nav-link-card-details py-3 mx-2 border dropdown-toggle px-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="width: 150px"
                      >
                        {{ category.NAME || "CATEGORY" }}
                      </button>
                      <div class="dropdown-menu">
                        <a
                          class="dropdown-item nav-item me-2 me-lg-0 d-block"
                          v-for="(item, i) in productCategories"
                          :key="i"
                          @click="getGroups(item)"
                        >
                          <a
                            class="nav-link nav-link-card-details py-3 px-2 border text-center"
                            role="button"
                            :class="
                              item.ID == selected_category
                                ? `bg-success text-white`
                                : ``
                            "
                            >{{ item.NAME }}
                          </a>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto">
                    <div class="btn-group mb-2 mb-md-0">
                      <button
                        class="bg-soft-success nav-link nav-link-card-details py-3 mx-2 border dropdown-toggle px-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="width: 150px"
                      >
                        {{ order_type.NAME || "Select Location" }}
                      </button>
                      <div class="dropdown-menu">
                        <a
                          class="dropdown-item nav-item me-2 me-lg-0 d-block"
                          v-for="(item, i) in order_types"
                          :key="i"
                          @click="selectOrderType(item)"
                        >
                          <a
                            class="nav-link nav-link-card-details py-3 px-2 border text-center"
                            role="button"
                            :class="
                              item.ID == order_type.ID
                                ? `bg-success text-white`
                                : ``
                            "
                            >{{ item.NAME }}
                          </a>
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 mb-2">
        <div class="col-12">
          <div class="row g-3">
            <!-- <div class="col-12">
              <div class="card bg-transparent-50 overflow-hidden">
                <div class="card-header position-relative">
                  <div
                    class="bg-holder d-none d-md-block bg-card z-index-1"
                    style="
                      background-image: url('assets/img/illustrations/ecommerce-bg.png');
                      background-size: 230px;
                      background-position: right bottom;
                      z-index: -1;
                    "
                  ></div>
                  <div class="position-relative z-index-2">
                    <div>
                      <h5 class="text-primary mb-1">
                        {{ getTimegreetings() }}, {{ user.FIRST_NAME }}!
                      </h5>
                      <p class="py-1">Here’s where you start selling</p>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <ul class="mb-0 list-unstyled">
                    <li
                      class="alert mb-0 rounded-0 py-3 px-card alert-warning border-x-0 border-top-0"
                    >
                      <div class="row flex-between-center">
                        <div class="col">
                          <div class="d-flex">
                            <div class="fas fa-circle mt-1 fs--2"></div>
                            <p class="fs--1 ps-2 mb-0">
                              <strong>5 order</strong> waiting your action
                            </p>
                          </div>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                          <a
                            class="alert-link fs--1 fw-medium"
                            @click="goToAllOrders"
                            >View orders<i
                              class="fas fa-chevron-right ms-1 fs--2"
                            ></i
                          ></a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
            <div
              class="card col-12 col-md-2 col-sm-6 col-xs-6 scrollbar list py-3 px-1 mb-3"
              style="max-height: 100%"
            >
              <h6
                class="border-bottom mb-1 dropdown-header mt-lg-0 text-uppercase px-card fs--4 pt-0 pb-2"
              >
                Groups
              </h6>
              <ul class="nav flex-lg-column fs--1">
                <li
                  v-for="(item, i) in groups"
                  class="nav-item me-2 me-lg-0 d-block"
                  @click="getMenuItemsByGroup(item.ID)"
                >
                  <a
                    class="nav-link nav-link-card-details py-3 mx-2 border"
                    role="button"
                    :class="
                      selected_group == item.ID ? `bg-primary text-white` : ``
                    "
                    >{{ item.NAME }}
                  </a>
                </li>
              </ul>
            </div>
            <!-- Start goups  -->
            <div class="col-12 col-lg-10 col-md-10 col-sm-6 col-xs-6">
              <div class="card mb-2">
                <div class="card-body">
                  <div class="row flex-between-center">
                    <div class="col-sm-auto mb-2 mb-sm-0">
                      <h6 class="mb-0">Showing {{ items.length }} Products</h6>
                    </div>
                    <div class="col-auto d-flex align-items-center">
                      <button class="btn btn-secondary" @click="goToAllOrders">
                        All Orders
                      </button>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <ul class="mb-0 list-unstyled">
                    <li
                      class="alert mb-0 rounded-0 py-2 px-card alert-warning border-x-0 border-top-0"
                    >
                      <div class="row flex-between-center">
                        <div class="col">
                          <div class="d-flex">
                            <div class="fas fa-circle mt-1 fs--2"></div>
                            <p class="fs--1 ps-2 mb-0">
                              {{ getTimegreetings() }} {{ user.FIRST_NAME }}
                              {{ user.LAST_NAME }},
                              <strong>Some orders</strong> are waiting your
                              action!
                            </p>
                          </div>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                          <a
                            class="alert-link fs--1 fw-medium"
                            @click="goToAllOrders"
                            role="button"
                            >View orders<i
                              class="fas fa-chevron-right ms-1 fs--2"
                            ></i
                          ></a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <!-- Edn goups  -->
              <div class="card mb-3">
                <div class="card-header">
                  <div class="row flex-between-center border-bottom pb-1">
                    <div class="col-4 col-sm-auto align-items-center pe-0">
                      <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                        <i class="fa fa-shopping-cart text-warning"></i>
                        Menu Items
                      </h5>
                      <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                        Search items by name or barcode to add to cart
                      </p>
                    </div>
                    <div class="col-8 col-sm-auto ms-auto text-end ps-0">
                      <div class="d-none" id="orders-bulk-actions">
                        <div class="d-flex">
                          <select
                            class="form-select form-select-sm"
                            aria-label="Bulk actions"
                          >
                            <option selected="">Bulk actions</option>
                            <option value="Refund">Refund</option>
                            <option value="Delete">Delete</option>
                            <option value="Archive">Archive</option></select
                          ><button
                            class="btn btn-falcon-default btn-sm ms-2"
                            type="button"
                          >
                            Apply
                          </button>
                        </div>
                      </div>
                      <div id="orders-actions" class="d-flex">
                        <div class="search-box h1">
                          <input
                            class="form-control search-input fuzzy-search"
                            type="text"
                            placeholder="Search..."
                            aria-label="Search"
                            v-model="searchValue"
                            v-on:keydown.enter="searchBarcode"
                          />
                          <span class="fas fa-search search-box-icon"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-md-8 col-mlg-8 col-xl-8">
                      <div v-if="items.length > 0">
                        <!-- Menu items table  -->
                        <EasyDataTable
                          id="tour-step-1"
                          table-class-name=""
                          alternating
                          buttons-pagination
                          :headers="headers"
                          :items="items"
                          :search-field="searchField"
                          :search-value="searchValue"
                          :loading="loading"
                          @click-row="addTocart"
                        >
                          <template #loading>
                            <div class="spinner-border" role="status">
                              <span class="visually-hidden">Loading...</span>
                            </div>
                          </template>
                          <template #item-NAME="item">
                            <div class="text-left">
                              <strong>{{ item.NAME }}</strong>
                            </div>
                          </template>
                          <template #item-PRICE="item">
                            <div class="text-right">
                              <strong>{{ item.PRICE }}</strong>
                            </div>
                          </template>
                          <template #item-action="item">
                            <div
                              class="dropdown font-sans-serif position-static"
                            >
                              <button
                                class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                                type="button"
                                id="order-dropdown-0"
                                data-bs-toggle="dropdown"
                                data-boundary="viewport"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                <span class="fas fa-ellipsis-h fs--1"></span>
                              </button>
                              <div
                                class="dropdown-menu dropdown-menu-end border py-0"
                                aria-labelledby="order-dropdown-0"
                              >
                                <div class="py-1">
                                  <a
                                    class="dropdown-item"
                                    role="button"
                                    data-bs-toggle="modal"
                                    :data-bs-target="'#edit-modal' + item.ID"
                                    >Edit</a
                                  >

                                  <div class="dropdown-divider"></div>
                                  <a
                                    class="dropdown-item text-danger"
                                    role="button"
                                    @click="deleteRecource(item)"
                                    >Delete</a
                                  >
                                </div>
                              </div>
                            </div>
                          </template>
                        </EasyDataTable>
                        <!-- End Menu items table  -->
                      </div>
                    </div>
                    <div class="col-12 col-md-4 col-mlg-4 col-xl-4">
                      <div
                        class="card border-right scrollbar list py-3 px-2 mx-1"
                        style="max-height: 100%"
                      >
                        <div class="card-header">
                          <div class="row justify-content-between">
                            <select
                              class="form-select form-select-sm mb-3"
                              style="width: 100%"
                              aria-label="Bulk actions"
                              v-model="formData.customer_id"
                            >
                              <option value="">Select Customer?</option>
                              <option
                                :value="item.ID"
                                v-for="(item, id) in customers"
                              >
                                {{ item.FIRST_NAME }} {{ item.LAST_NAME }}
                              </option>
                            </select>
                            <h6 class="mb-md-0">
                              Shopping Cart ({{ cart.length || "No" }}
                              Items)
                            </h6>
                          </div>
                        </div>
                        <div class="card-body p-0">
                          <div class="table-responsive scrollbar">
                            <table class="table table-bordered table-striped">
                              <thead>
                                <tr>
                                  <th
                                    class="fs--1"
                                    scope="col"
                                    style="width: 60%"
                                  >
                                    Name
                                  </th>
                                  <th class="text-end fs--1" style="width: 10%">
                                    Qty/Actions
                                  </th>
                                  <!-- <th scope="col">Price</th> -->
                                  <th class="text-end fs--1" style="width: 30%">
                                    Total
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr v-for="(item, i) in cartItems" :key="i">
                                  <td class="fs--1">
                                    {{ truncateText(item.NAME, 50) }}
                                  </td>
                                  <td class="fs--1">
                                    <div>
                                      <div
                                        class="input-group input-group-sm flex-nowrap"
                                        data-quantity="data-quantity"
                                      >
                                        <button
                                          class="btn btn-sm btn-outline-secondary border-300 px-2 shadow-none"
                                          data-type="minus"
                                          @click="
                                            editItem(item, item.QUANTITY - 1)
                                          "
                                        >
                                          -</button
                                        ><input
                                          class="form-control text-center px-2 input-spin-none fs--1"
                                          type="number"
                                          min="1"
                                          :value="item.QUANTITY"
                                          aria-label="Amount (to the nearest dollar)"
                                          style="width: 50px"
                                        /><button
                                          class="btn btn-sm btn-outline-secondary border-300 px-2 shadow-none"
                                          data-type="plus"
                                          @click="
                                            editItem(item, item.QUANTITY + 1)
                                          "
                                        >
                                          +
                                        </button>
                                      </div>
                                      <button
                                        class="btn btn-link p-0 ms-2 text-danger fs--1"
                                        type="button"
                                        data-bs-toggle="tooltip"
                                        data-bs-placement="top"
                                        title="Delete"
                                        @click="deleteItem(item)"
                                      >
                                        Delete
                                        <span
                                          class="text-500 fas fa-trash-alt text-danger"
                                        ></span>
                                      </button>
                                    </div>
                                  </td>
                                  <td class="text-end fs--1">
                                    {{
                                      formatToCurrency(
                                        item.PRICE * item.QUANTITY
                                      )
                                    }}
                                  </td>
                                </tr>
                                <tr class="bg-light fs--1">
                                  <td
                                    class="text-900 fs--1 fw-bold"
                                    colspan="2"
                                  >
                                    TOTAL
                                  </td>
                                  <td class="fs--3 fw-bold text-end">
                                    {{ formatToCurrency(totalValue) }}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div class="card-footer bg-light row mb-0">
                          <button
                            @click="clearCart"
                            class="btn btn-lg btn-warning py-3 col-6 border"
                          >
                            <i class="fa fa-cart-arrow-down me-2 mt-1"></i
                            ><br />Empty
                          </button>
                          <button
                            @click="orderTicket"
                            class="btn btn-lg btn-primary py-3 col-6 border"
                          >
                            <i class="fa fa-cart-plus me-2 mt-1"></i><br />Order
                          </button>
                          <button
                            @click="payMpesa"
                            class="btn btn-lg btn-success py-3 col-6 border"
                          >
                            <i class="fa fa-credit-card me-2 mt-1"></i
                            ><br />MPESA
                          </button>
                          <button
                            @click="payCash"
                            class="btn btn-lg btn-success py-3 col-6 border"
                          >
                            <i class="fa fa-check-square me-2 mt-1"></i
                            ><br />CASH
                          </button>

                          <!-- Footer  -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer bg-light d-flex justify-content-center">
                  <div>Bulla</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterComp />
    </div>
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import CartService from "../services/cart.service";
import TablesService from "../services/tables.service";
import TicketService from "../services/ticket.service";
import ProductService from "../services/product.service";
import CustomerService from "../services/customer.service";
import OrderTypeService from "../services/order_types.service";

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    EasyDataTable,
  },
  data() {
    return {
      checkout: false,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        customer_id: "",
        order_type_id: "",
        order_type_name: "",
        cart: {},
        total_amount: 0,
      },
      searchKey: "",
      barcode: "",
      loading_qr: false,
      dialog: false,
      items: {},
      tables: [],
      order_types: [],
      dialogQty: false,
      itemDialog: false,
      productCategories: [],
      customers: [],
      groups: [],
      selectedProductCategory: "",
      cart: {},
      table_selection: localStorage.getItem("table") == null ? true : false,
      category_selection: false,
      order_type_selection: true,
      order_type:
        localStorage.getItem("order_type") == null
          ? {}
          : JSON.parse(localStorage.getItem("order_type")),

      selectedCategory: 0,
      selected_category: "",
      selected_category_data: {},
      selected_group: "",
      selected_table: "",
      selected_customer: "",
      searchValue: "",
      searchField: ["ID", "NAME", "PRICE", "BARCODE"],
      headers: [
        // { text: "#", value: "ID", sortable: true, widh: 10 },
        { text: "PRODUCT NAME", value: "NAME", sortable: true },
        // { text: "Group", value: "GROUP_ID", sortable: true },
        // { text: "Units", value: "UNIT_NAME", sortable: true },
        // { text: "B.Price", value: "BUY_PRICE", sortable: true },
        { text: "PRICE (KES)", value: "PRICE", sortable: true, width: 100 },
        // { text: "Details", value: "DESCRIPTION", sortable: true },
        { text: "BARCODE", value: "BARCODE", sortable: true,  width: 100 },
        // { text: "Stock", value: "STOCK_AMOUNT", sortable: true },
        // { text: "Action", value: "action" },
      ],
    };
  },
  async created() {
    // this.forceReload();
    this.$store.commit("SET_LOADING", true);
    await this.getMenuCategories();
    this.selected_category = this.productCategories.at(0).ID;
    await this.getMenuGroupsByCategory();
    await this.getMenuItemsByGroup(this.groups.at(0).ID);
    // await this.getAllTables();
    this.selected_table = this.tables.at(0);
    await this.getAllOrderTypes();
    this.getCustomers();
    this.$store.commit("SET_LOADING", false);
  },
  mounted() {
    try {
      var isFluid = JSON.parse(localStorage.getItem("isFluid"));
      if (isFluid) {
        var container = document.querySelector("[data-layout]");
        container.classList.remove("container");
        container.classList.add("container-fluid");
      }
      var navbarStyle = localStorage.getItem("navbarStyle");
      if (navbarStyle && navbarStyle !== "transparent") {
        document
          .querySelector(".navbar-vertical")
          .classList.add(`navbar-${navbarStyle}`);
      }
      var navbarPosition = localStorage.getItem("navbarPosition");
      var navbarVertical = document.querySelector(".navbar-vertical");
      var navbarTopVertical = document.querySelector(".content .navbar-top");
      var navbarTop = document.querySelector(
        "[data-layout] .navbar-top:not([data-double-top-nav"
      );
      var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
      var navbarTopCombo = document.querySelector(
        '.content [data-navbar-top="combo"]'
      );

      if (localStorage.getItem("navbarPosition") === "double-top") {
        document.documentElement.classList.toggle("double-top-nav-layout");
      }

      if (navbarPosition === "top") {
        navbarTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTopCombo.remove(navbarTopCombo);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "combo") {
        navbarVertical.removeAttribute("style");
        navbarTopCombo.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarTopVertical.remove(navbarTopVertical);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "double-top") {
        navbarDoubleTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTop.remove(navbarTop);
        navbarTopCombo.remove(navbarTopCombo);
      } else {
        navbarVertical.removeAttribute("style");
        navbarTopVertical.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarDoubleTop.remove(navbarDoubleTop);
        navbarTopCombo.remove(navbarTopCombo);
      }
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    totalValue() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        totalValue =
          totalValue + parseFloat(element.QUANTITY) * parseFloat(element.PRICE);
      });
      return parseFloat(totalValue);
    },
    cartLength() {
      let data = this.cart;
      if (data.length) {
        return data.length;
      } else {
        return "empty";
      }
    },
    cartItems() {
      let cartData = Object.values(this.cart);
      return cartData;
    },
    table() {
      return localStorage.getItem("table") == null ? {} : this.selected_table;
    },
    category() {
      return this.selected_category_data;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    searchBarcode(){
        console.log('Barcode...')
        console.log(this.searchValue)
        // Start the barcode search 
    },
    deleteItem(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    editItem(item, quantity) {
      if (quantity == 0) {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.ID == item.ID);
        existingElement.QUANTITY = quantity;
        existingElement.SUB_TOTAL = quantity * item.PRICE;
      }
      this.cart = myCart;
      toast.success("1 " + item.NAME + " updated in cart");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    toggleCheckout(val) {
      this.checkout = val;
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    sortItems(list) {
      return list.sort((a, b) => (a.NAME > b.NAME ? 1 : -1));
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    tenderedInput() {
      // this.formData.balance_amount =  this.formData.tender_amount;
      this.formData.balance_amount =
        parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
    },
    payMpesa() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "MPESA";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCash() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CASH";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    completeCheckout() {
      this.preview_cash_modal = false;
      this.preview_dc_modal = false;
      this.digital_change = false;
      this.change_sent = false;
      this.qrData = {};
      this.clearCart();
      this.$notify({
        group: "foo",
        type: "success",
        title: "Checkout complete",
        text: "order places and paid successfuly!",
      });
    },
    getGroups(cat) {
      console.log(cat);
      this.selected_category_data = cat;
      this.selected_category = cat.ID;
      if (this.selected_category == null) {
        this.getItemsByOrder(this.order_type);
      } else {
        this.getMenuGroupsByCategory();
      }
      // this.getMenuItemsByGroup(this.groups[0].ID);
    },
    getMenuGroupsByCategory() {
      let formData = {
        menu_category: this.selected_category,
      };
      return ProductService.getMenuGroupsByCategory(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    filterItems() {
      this.searchKey = this.searchKey.toLowerCase();
      if (this.searchKey) {
        let array = Object.values(this.items);
        this.items = array.filter((item) => {
          let name = item.NAME.toLowerCase();
          return name.includes(this.searchKey);
        });
      } else {
        this.getAll();
      }
    },
    orderTicket() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Order placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    clearCart() {
      this.cart = {};
      toast.info("Cart cleared!");
    },
    goToMyOrders() {
      this.$router.push("/my-orders");
    },
    goToAllOrders() {
      this.$router.push("/pos-open-order");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    getItemsByOrder(item) {
      return CartService.byOrder(item.ID).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAll() {
      return CartService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.items = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuItemsByGroup(id) {
      this.$store.commit("SET_LOADING", true);
      this.selected_group = id;
      let formData = {
        menu_group: this.selected_group,
      };
      return ProductService.getMenuItemsByGroup(formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllTables() {
      return TablesService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tables = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
            this.getItemsByOrder(response.data.data.at(0).ID);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuGroupsByOrder() {
      let formData = {
        order_type: this.order_type.ID,
      };
      return ProductService.getMenuGroupsByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getCustomers() {
      // samdoh
      return CustomerService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.customers = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllProducts() {
      return ProductService.getAll().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllGroups() {
      return ProductService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategories() {
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    addTocart(item) {
      console.log("Adding to cart...");
      console.log(item);
      // this.$store.dispatch("pos/addToCart", item);
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.ID == item.ID);
        item.SUB_TOTAL = item.QUANTITY * item.PRICE;
        existingElement.QUANTITY = existingElement.QUANTITY + 1;
        existingElement.SUB_TOTAL =
          existingElement.SUB_TOTAL + item.QUANTITY * item.PRICE;
      } else {
        item.QUANTITY = 1;
        item.SUB_TOTAL = item.QUANTITY * item.PRICE;
        myCart.push(item);
      }
      this.cart = myCart;
      toast.info("1 " + item.NAME + " added to cart!", {
        autoClose: 2000,
      });
    },
    selectTable(item) {
      this.table_selection = false;
      localStorage.setItem("table", JSON.stringify(item));
      this.$notify({
        group: "foo",
        type: "info",
        title: "Selection",
        text: "Table selected!",
      });
    },
    selectOrderType(item) {
      this.order_type = item;
      this.table_selection = true;
      this.order_type_selection = false;
      localStorage.setItem("order_type", JSON.stringify(item));
      toast.success(item.NAME + " selected!");
      this.getItemsByOrder(item);
      this.getMenuGroupsByOrder();
    },
  },
};
</script>
