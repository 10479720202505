<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />

      <div class="row mb-2">
        <div class="col ms-n2 pe-1">
          <div class="card bg-100 shadow-none border">
            <div class="row gx-0 flex-between-center">
              <div class="col-sm-auto d-flex align-items-center">
                <img
                  class="ms-n2"
                  src="assets/img/illustrations/crm-bar-chart.png"
                  alt=""
                  width="90"
                />
                <div>
                  <h6 class="text-primary fs--1 mb-0">
                    {{ getTimegreetings() }} {{ user.FIRST_NAME }}
                    {{ user.LAST_NAME }}!
                  </h6>
                  <h4 class="text-primary fw-bold mb-0">
                    <!-- Bulla  -->
                    <span class="text-info fw-medium">EDMS</span>
                  </h4>
                </div>
                <img
                  class="ms-n4 d-md-none d-lg-block"
                  src="assets/img/illustrations/crm-line-chart.png"
                  alt=""
                  width="150"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 mb-2">
        <div class="col-12">
          <div class="row g-3">
            <!-- <div class="col-12">
              <div class="card bg-transparent-50 overflow-hidden">
                <div class="card-header position-relative">
                  <div
                    class="bg-holder d-none d-md-block bg-card z-index-1"
                    style="
                      background-image: url('assets/img/illustrations/ecommerce-bg.png');
                      background-size: 230px;
                      background-position: right bottom;
                      z-index: -1;
                    "
                  ></div>
                  <div class="position-relative z-index-2">
                    <div>
                      <h5 class="text-primary mb-1">
                        {{ getTimegreetings() }}, {{ user.FIRST_NAME }}!
                      </h5>
                      <p class="py-1">Here’s where you start selling</p>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <ul class="mb-0 list-unstyled">
                    <li
                      class="alert mb-0 rounded-0 py-3 px-card alert-warning border-x-0 border-top-0"
                    >
                      <div class="row flex-between-center">
                        <div class="col">
                          <div class="d-flex">
                            <div class="fas fa-circle mt-1 fs--2"></div>
                            <p class="fs--1 ps-2 mb-0">
                              <strong>5 order</strong> waiting your action
                            </p>
                          </div>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                          <a
                            class="alert-link fs--1 fw-medium"
                            @click="goToAllOrders"
                            >View orders<i
                              class="fas fa-chevron-right ms-1 fs--2"
                            ></i
                          ></a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
            <div
              class="card col-12 col-md-2 col-sm-6 col-xs-6 scrollbar list py-3 px-1 mb-3"
              style="max-height: 100%"
            >
              <h6
                class="border-bottom ms-n3 mb-1 dropdown-header mt-lg-0 text-uppercase px-card fs--4 pt-0 pb-2"
              >
                <i class="fa fa-folder-open text-warning icon-spin"></i>
                Directories
              </h6>
              <!-- Start of tree view  -->
              <!-- samdoh -->
              <ul
                class="mb-0 treeview treeview-stripe"
                id="treeviewStriped"
                data-options='{"striped":true}'
              >
                <li
                  v-for="(item, i) in order_types"
                  :key="i"
                  @click="selectOrderType(item)"
                  class="treeview-list-item"
                >
                  <a
                    data-bs-toggle="collapse"
                    :href="`#treeviewStriped-1-1_${item.ID}`"
                    role="button"
                    aria-expanded="false"
                  >
                    <p class="treeview-text">
                      {{ item.NAME }}
                      <span
                        v-if="item.folders.length > 0"
                        class="badge rounded-pill badge-soft-primary ms-2"
                        >{{ item.folders.length }}</span
                      >
                    </p>
                  </a>
                  <ul
                    class="collapse treeview-list"
                    :id="`treeviewStriped-1-1_${item.ID}`"
                    data-show="false"
                  >
                    <!-- Start of folders/categories  -->
                    <li
                      v-for="(folder, i) in item.folders"
                      :key="i"
                      @click="categorySelected(folder)"
                      class="treeview-list-item"
                    >
                      <a
                        data-bs-toggle="collapse"
                        :href="`#treeviewStriped-2-1_folder_${folder.ID}`"
                        role="button"
                        aria-expanded="false"
                      >
                        <p class="treeview-text">
                          {{ folder.NAME }}
                          <span
                            v-if="folder.menu_groups.length > 0"
                            class="badge rounded-pill badge-soft-primary ms-2"
                            >{{ folder.menu_groups.length }}</span
                          >
                        </p>
                      </a>
                      <ul
                        class="collapse treeview-list"
                        :id="`treeviewStriped-2-1_folder_${folder.ID}`"
                        data-show="false"
                      >
                        <!-- Start menu groups/tags  -->
                        <li
                          v-for="(group, i) in folder.menu_groups"
                          :key="i"
                          @click="categorySelected(group)"
                          class="treeview-list-item"
                        >
                          <a
                            data-bs-toggle="collapse"
                            :href="`#treeviewStriped-3-1_groups_${group.ID}`"
                            role="button"
                            aria-expanded="false"
                          >
                            <p class="treeview-text">
                              {{ group.NAME
                              }}<span
                                v-if="group.menu_items.length > 0"
                                class="badge rounded-pill badge-soft-primary ms-2"
                                >{{ group.menu_items.length }}</span
                              >
                            </p>
                          </a>
                          <ul
                            class="collapse treeview-list"
                            :id="`treeviewStriped-3-1_groups_${group.ID}`"
                            data-show="true"
                          >
                            <!-- Start of docs  -->
                            <li
                              v-for="(doc, i) in group.menu_items"
                              :key="i"
                              @click="documentClicked(doc)"
                              class="treeview-list-item"
                            >
                              <div class="treeview-item">
                                <a class="flex-1" href="#!">
                                  <p class="treeview-text">
                                    <span
                                      class="me-2 fas fa-pdf text-success"
                                    ></span>
                                    {{ doc.NAME }}
                                  </p>
                                </a>
                                <div class="dot bg-info"></div>
                              </div>
                            </li>
                          </ul>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </li>
              </ul>
              <!-- End of tree view  -->
            </div>
            <!-- Start goups  -->
            <div class="col-12 col-lg-10 col-md-10 col-sm-6 col-xs-6">
              <div class="card mb-2">
                <div class="card-body">
                  <div class="row flex-between-center">
                    <div class="col-sm-auto mb-2 mb-sm-0">
                      <h6 class="mb-0">
                        Showing {{ documents.length }} Documents
                      </h6>
                    </div>
                  </div>
                </div>
                <!-- <div class="card-body p-0">
                  <ul class="mb-0 list-unstyled">
                    <li
                      class="alert mb-0 rounded-0 py-2 px-card alert-warning border-x-0 border-top-0"
                    >
                      <div class="row flex-between-center">
                        <div class="col">
                          <div class="d-flex">
                            <div class="fas fa-circle mt-1 fs--2"></div>
                            <p class="fs--1 ps-2 mb-0">
                              {{ getTimegreetings() }} {{ user.FIRST_NAME }}
                              {{ user.LAST_NAME }},
                              <strong>Some orders</strong> are waiting your
                              action!
                            </p>
                          </div>
                        </div>
                        <div class="col-auto d-flex align-items-center">
                          <a
                            class="alert-link fs--1 fw-medium"
                            @click="goToAllOrders"
                            role="button"
                            >View orders<i
                              class="fas fa-chevron-right ms-1 fs--2"
                            ></i
                          ></a>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div> -->
              </div>
              <!-- Edn goups  -->
              <div class="card">
                <div class="card-body">
                  <div class="row g-1">
                    <div
                      v-if="documents.length > 0"
                      @click="addTocart(item)"
                      class="fs--2 col-12 col-md-4 border rounded d-flex"
                      role="button"
                      v-for="(item, i) in documents"
                      :key="i"
                    >
                      <div class="bg-soft-primary ml-0 px-3 py-2">
                        <i
                          class="fa fa-file-pdf fa-5x text-warning"
                          aria-hidden="true"
                        ></i>
                      </div>
                      <div class="notification-body px-2 py-1">
                        <p class="mb-1 fw-bold">
                          {{ truncateText(item.NAME, 15).toUpperCase() }}
                        </p>
                        <span class="notification-time"
                          ><span class="me-2" role="img" aria-label="Emoji">
                            <input
                              class="form-check-input"
                              :id="`flexRadio${item.NAME}`"
                              type="radio"
                              :name="item.NAME"
                              :checked="selected_documents.includes(item.ID)"
                            /> </span
                          >Just now</span
                        >
                        <br />
                        <span class="notification-time">
                          {{ formatDate(item.created_at) }}
                        </span>
                      </div>
                    </div>

                    <!-- <div
                          class="col-6 col-md-3 col-lg-3 border rounded-1 d-flex flex-column justify-content-between"
                          v-for="(item, i) in documents"
                          :key="i"
                        >
                          <div class="overflow-hidden">
                            <div
                              class="position-relative rounded-top overflow-hidden"
                            >
                              <a class="d-block text-center" role="button">
                                <img
                                  v-if="item.IMAGE_URL"
                                  class="rounded-3 mt-3 img-fluid"
                                  :src="item.IMAGE_URL"
                                  alt="Product Image"
                                />
                                <div v-else>
                                  <img
                                    class="rounded-3 img-fluid mt-3"
                                    src="assets/img/no-image.jpg"
                                    alt="Product Image"
                                    height="32"
                                  />
                                </div>
                              </a>
                            </div>
                            <div class="text-center">
                              <h5 class="fs--1">
                                <a class="text-dark" role="button">
                                  {{
                                    truncateText(item.NAME, 15).toUpperCase()
                                  }}
                                </a>
                              </h5>
                              <span
                                class="fs--3 fw-bold text-warning d-block"
                                role="button"
                              >
                                Size: {{ item.PRICE }} MBs
                              </span>
                            </div>
                          </div>
                        </div> -->
                    <p v-else>No item found!</p>
                  </div>
                </div>
                <div class="card-footer bg-light d-flex justify-content-center">
                  <!-- <div>Bulla</div> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterComp />
    </div>
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import CartService from "../services/cart.service";
import TablesService from "../services/tables.service";
import TicketService from "../services/ticket.service";
import ProductService from "../services/product.service";
import CustomerService from "../services/customer.service";
import OrderTypeService from "../services/order_types.service";

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
  },
  data() {
    return {
      checkout: false,
      active_item: {},
      daily_analysis: [],
      selected_documents: [],
      weekly_analysis: [],
      formData: {
        customer_id: "",
        order_type_id: "",
        order_type_name: "",
        cart: {},
        total_amount: 0,
      },

      searchKey: "",
      barcode: "",
      loading_qr: false,
      dialog: false,
      documents: {},
      tables: [],
      order_types: [],
      dialogQty: false,
      itemDialog: false,
      productCategories: [],
      customers: [],
      groups: [],
      selectedProductCategory: "",
      cart: {},
      table_selection: localStorage.getItem("table") == null ? true : false,
      category_selection: false,
      order_type_selection: true,
      order_type:
        localStorage.getItem("order_type") == null
          ? {}
          : JSON.parse(localStorage.getItem("order_type")),

      selectedCategory: 0,
      selected_category: "",
      selected_category_data: {},
      selected_group: "",
      selected_table: "",
      selected_customer: "",
    };
  },
  async created() {
    // this.forceReload();
    this.$store.commit("SET_LOADING", true);
    await this.getAllOrderTypes();
    if (this.order_types.length > 0)
      await this.getMenuCategoryByOrder(this.order_types.at(0).ID);
    if (this.productCategories.length > 0) {
      await this.getItemsByOrder(this.productCategories.at(0));
      this.selected_category = this.productCategories.at(0).ID;
      await this.getMenuGroupsByCategory();
      await this.getMenuItemsByGroup(this.groups.at(0).ID);
    }
    this.getAllTables();
    this.getCustomers();
    this.$store.commit("SET_LOADING", false);
  },
  mounted() {
    try {
      var isFluid = JSON.parse(localStorage.getItem("isFluid"));
      if (isFluid) {
        var container = document.querySelector("[data-layout]");
        container.classList.remove("container");
        container.classList.add("container-fluid");
      }
      var navbarStyle = localStorage.getItem("navbarStyle");
      if (navbarStyle && navbarStyle !== "transparent") {
        document
          .querySelector(".navbar-vertical")
          .classList.add(`navbar-${navbarStyle}`);
      }
      var navbarPosition = localStorage.getItem("navbarPosition");
      var navbarVertical = document.querySelector(".navbar-vertical");
      var navbarTopVertical = document.querySelector(".content .navbar-top");
      var navbarTop = document.querySelector(
        "[data-layout] .navbar-top:not([data-double-top-nav"
      );
      var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
      var navbarTopCombo = document.querySelector(
        '.content [data-navbar-top="combo"]'
      );

      if (localStorage.getItem("navbarPosition") === "double-top") {
        document.documentElement.classList.toggle("double-top-nav-layout");
      }

      if (navbarPosition === "top") {
        navbarTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTopCombo.remove(navbarTopCombo);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "combo") {
        navbarVertical.removeAttribute("style");
        navbarTopCombo.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarTopVertical.remove(navbarTopVertical);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "double-top") {
        navbarDoubleTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTop.remove(navbarTop);
        navbarTopCombo.remove(navbarTopCombo);
      } else {
        navbarVertical.removeAttribute("style");
        navbarTopVertical.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarDoubleTop.remove(navbarDoubleTop);
        navbarTopCombo.remove(navbarTopCombo);
      }
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    totalValue() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        totalValue =
          totalValue + parseFloat(element.QUANTITY) * parseFloat(element.PRICE);
      });
      return parseFloat(totalValue);
    },
    cartLength() {
      let data = this.cart;
      if (data.length) {
        return data.length;
      } else {
        return "empty";
      }
    },
    cartItems() {
      let cartData = Object.values(this.cart);
      return cartData;
    },
    table() {
      return localStorage.getItem("table") == null ? {} : this.selected_table;
    },
    category() {
      return this.selected_category_data;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    documentClicked(item) {},
    deleteItem(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    editItem(item, quantity) {
      if (quantity == 0) {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.ID == item.ID);
        existingElement.QUANTITY = quantity;
        existingElement.SUB_TOTAL = quantity * item.PRICE;
      }
      this.cart = myCart;
      toast.success("1 " + item.NAME + " updated in cart");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    toggleCheckout(val) {
      this.checkout = val;
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    sortItems(list) {
      return list.sort((a, b) => (a.NAME > b.NAME ? 1 : -1));
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    tenderedInput() {
      // this.formData.balance_amount =  this.formData.tender_amount;
      this.formData.balance_amount =
        parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
    },
    payMpesa() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "MPESA";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCash() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CASH";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    completeCheckout() {
      this.preview_cash_modal = false;
      this.preview_dc_modal = false;
      this.digital_change = false;
      this.change_sent = false;
      this.qrData = {};
      this.clearCart();
      this.$notify({
        group: "foo",
        type: "success",
        title: "Checkout complete",
        text: "order places and paid successfuly!",
      });
    },
    categorySelected(cat) {
      console.log(cat);
      this.selected_category_data = cat;
      this.selected_category = cat.ID;
      if (this.selected_category == null) {
        this.getItemsByOrder(this.order_type);
      } else {
        this.getMenuGroupsByCategory();
      }

      this.selected_group = this.group.length > 0 ? this.groups[0].ID : "";
      this.getMenuItemsByGroup(this.selected_group);
    },
    getMenuGroupsByCategory() {
      let formData = {
        menu_category: this.selected_category,
      };
      return ProductService.getMenuGroupsByCategory(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },

    filterItems() {
      this.searchKey = this.searchKey.toLowerCase();
      if (this.searchKey) {
        let array = Object.values(this.documents);
        this.documents = array.filter((item) => {
          let name = item.NAME.toLowerCase();
          return name.includes(this.searchKey);
        });
      } else {
        this.getAll();
      }
    },
    orderTicket() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Order placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    clearCart() {
      this.cart = {};
      toast.info("Cart cleared!");
    },
    goToMyOrders() {
      this.$router.push("/my-orders");
    },
    goToAllOrders() {
      this.$router.push("/pos-open-order");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    getItemsByOrder(item) {
      return CartService.byOrder(item.ID).then(
        (response) => {
          if (response.data.code == 200) {
            this.documents = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAll() {
      return CartService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.documents = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuItemsByGroup(id) {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        menu_group: id,
      };
      return ProductService.getMenuItemsByGroup(formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.documents = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllTables() {
      return TablesService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tables = response.data.data;
            if (this.tables.length > 0) this.selected_table = this.tables.at(0);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuGroupsByOrder() {
      let formData = {
        order_type: this.order_type.ID,
      };
      return ProductService.getMenuGroupsByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getCustomers() {
      // samdoh
      return CustomerService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.customers = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllProducts() {
      return ProductService.getAll().then(
        (response) => {
          if (response.data.status == "success") {
            this.documents = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllGroups() {
      return ProductService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.documents = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategoryByOrder(order_type) {
      if (this.order_types.length > 0) {
        this.formData.order_type = order_type;
        return ProductService.getMenuCategoryByOrder(this.formData).then(
          (response) => {
            if (response.data.code == 200) {
              this.productCategories = response.data.data;
            } else {
              console.log(response.data.error);
            }
          },
          (error) => {
            console.log(error);
            if (error.response.status == 401) {
              this.handleLogout();
            }
          }
        );
      } else {
        this.productCategories = [];
      }
    },
    addTocart(item) {
      this.active_item = item;
      if (this.selected_documents.includes(item.ID))
        this.selected_documents = this.selected_documents.filter(
          (el) => el != item.ID
        );
      else this.selected_documents.push(item.ID);
      console.log(this.selected_documents);
    },
    selectTable(item) {
      this.table_selection = false;
      localStorage.setItem("table", JSON.stringify(item));
      this.$notify({
        group: "foo",
        type: "info",
        title: "Selection",
        text: "Table selected!",
      });
    },
    async selectOrderType(item) {
      this.order_type = item;
      this.table_selection = true;
      this.order_type_selection = false;
      localStorage.setItem("order_type", JSON.stringify(item));
      // toast.info(item.NAME + " selected!");
      this.getItemsByOrder(item);
      await this.getMenuCategoryByOrder(item.ID);
      await this.getMenuGroupsByOrder();
      this.selected_group = this.group.length > 0 ? this.groups[0].ID : "";
    },
  },
};
</script>
