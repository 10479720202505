<template>
  <div class="container" data-layout="container">
    <!-- Start of navdrawer  -->
    <NavDrawer />
    <!-- End of navbar  -->
    <div class="content">
      <!-- Start app bar  -->
      <AppBar />
      <!-- End app bar -->
      <div class="row g-3 mb-3">
        <div class="col-12">
          <div class="row g-3">
            <div class="col-12">
              <div class="card bg-transparent-50 overflow-hidden">
                <div class="card-header position-relative">
                  <div
                    class="bg-holder d-none d-md-block bg-card z-index-1"
                    style="
                      background-image: url('../assets/img/illustrations/crm-bar-chart.png');
                      background-position: right bottom;
                      z-index: -1;
                    "
                  ></div>
                  <!--/.bg-holder-->
                  <div class="position-relative z-index-2">
                    <div>
                      <h5 class="text-primary mb-1">
                        {{ getTimegreetings() }}, {{ user.FIRST_NAME }}!
                      </h5>
                      <p class="fs--1 mt-2">
                        Here’s what happening with your directory
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="row g-3 mb-3">
                <div class="col-sm-4">
                  <div class="card overflow-hidden" style="min-width: 12rem">
                    <div
                      class="bg-holder bg-card"
                      style="
                        background-image: url('../assets/img/icons/spot-illustrations/corner-1.png');
                      "
                    ></div>
                    <!--/.bg-holder-->
                    <div
                      class="card-body position-relative"
                      :key="daily_analysis.customers"
                    >
                      <h6>
                        Users<span
                          class="badge badge-soft-warning rounded-pill ms-2"
                          >All</span
                        >
                      </h6>
                      <count-up
                        class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-warning"
                        :end-val="daily_analysis.customers"
                        :start-val="0"
                      ></count-up>
                      <a
                        class="fw-semi-bold fs--1 text-nowrap"
                        @click="goToCustomers"
                        role="button"
                        >See all<span
                          class="fas fa-angle-right ms-1"
                          data-fa-transform="down-1"
                        ></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="col-sm-4">
                  <div class="card overflow-hidden" style="min-width: 12rem">
                    <div
                      class="bg-holder bg-card"
                      style="
                        background-image: url('../assets/img/icons/spot-illustrations/corner-2.png');
                      "
                    ></div>
                    <!--/.bg-holder-->
                    <div class="card-body position-relative">
                      <h6>
                        Uploaded Documents
                        <span class="badge badge-soft-info rounded-pill ms-2"
                          >this Week</span
                        >
                      </h6>
                      <count-up
                        class="display-4 fs-4 mb-2 fw-normal font-sans-serif text-info"
                        :end-val="weekly_analysis.closed_orders"
                        :start-val="0"
                      ></count-up>

                      <a
                        class="fw-semi-bold fs--1 text-nowrap"
                        @click="goToMenuItems"
                        role="button"
                        >All documents<span
                          class="fas fa-angle-right ms-1"
                          data-fa-transform="down-1"
                        ></span
                      ></a>
                    </div>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="card h-md-100 ecommerce-card-min-width">
                    <div class="card-header pb-0">
                      <h6 class="mb-0 mt-2 d-flex align-items-center">
                        Weekly Uploads<span
                          class="ms-1 text-400"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Calculated according to last week's sales"
                          ><span
                            class="far fa-question-circle"
                            data-fa-transform="shrink-1"
                          ></span
                        ></span>
                      </h6>
                    </div>
                    <div
                      class="card-body d-flex flex-column justify-content-end"
                    >
                      <div class="row">
                        <div class="col">
                          <count-up
                            class="display-4 fs-3 mb-2 fw-normal font-sans-serif text-success"
                            :end-val="weekly_analysis.sales"
                            :start-val="0"
                          >
                            <template #suffix>
                              <span style="me-2"> Docs</span>
                            </template>
                          </count-up>
                        </div>
                        <div class="col-auto ps-0">
                          <div
                            class="echart-bar-weekly-sales h-100 echart-bar-weekly-sales-smaller-width"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card h-md-100 h-100">
                    <div class="card-body" :key="chart_key">
                      <div class="row h-100 justify-content-between g-0">
                        <h6 class="mt-1">Folders</h6>
                        <div class="fs--2 mt-3" v-if="weekly_analysis">
                          <vue-echarts
                            :option="market_share_chart_option"
                            style="height: 350px; width: 100%"
                            ref="chart"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-6">
                  <div class="card h-md-100 h-100">
                    <div class="card-body" :key="chart_key">
                      <div class="row h-100 justify-content-between g-0">
                        <h6 class="mt-1">Upload Analysis</h6>
                        <div class="fs--2 mt-3" v-if="weekly_analysis">
                          <vue-echarts
                            :option="sales_chart_option"
                            style="height: 350px; width: 100%"
                            ref="chart"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="col-12">
          <div class="card py-3 mb-3">
            <div class="card-body py-3">
              <div class="row g-0">
                <div
                  class="col-6 col-md-4 border-200 border-bottom border-end pb-4"
                >
                  <h6 class="pb-1 text-700">Paid Orders</h6>
                  <p class="font-sans-serif lh-1 mb-1 fs-2">
                    {{ weekly_analysis.total_orders }}
                  </p>
                  <div class="d-flex align-items-center">
                    <h6 class="fs--1 text-500 mb-0">
                      {{ weekly_analysis.sales }} Document(s)
                    </h6>
                    <h6 class="fs--2 ps-3 mb-0 text-primary">
                      <span class="me-1 fas fa-caret-up"></span
                      >{{
                        weekly_analysis.closed_orders > 0
                          ? Math.floor(
                              (weekly_analysis.closed_orders /
                                weekly_analysis.total_orders) *
                                100
                            )
                          : 0
                      }}%
                    </h6>
                  </div>
                </div>
                <div
                  class="col-6 col-md-4 border-200 border-md-200 border-bottom border-md-end pb-4 ps-3"
                >
                  <h6 class="pb-1 text-700">Debts</h6>
                  <p class="font-sans-serif lh-1 mb-1 fs-2">
                    {{ weekly_analysis.total_orders }}
                  </p>
                  <div class="d-flex align-items-center">
                    <h6 class="fs--1 text-500 mb-0">
                      {{ formatToCurrency(weekly_analysis.debts) }}
                    </h6>
                    <h6 class="fs--2 ps-3 mb-0 text-warning">
                      <span class="me-1 fas fa-caret-up"></span
                      >{{
                        weekly_analysis.closed_orders > 0
                          ? Math.floor(
                              (weekly_analysis.total_orders -
                                weekly_analysis.closed_orders /
                                  weekly_analysis.total_orders) *
                                100
                            )
                          : 0
                      }}%
                    </h6>
                  </div>
                </div>
                <div
                  class="col-6 col-md-4 border-200 border-bottom border-end border-md-end-0 pb-4 pt-4 pt-md-0 ps-md-3"
                >
                  <h6 class="pb-1 text-700">Voids</h6>
                  <p class="font-sans-serif lh-1 mb-1 fs-2">
                    {{ weekly_analysis.total_orders }}
                  </p>
                  <div class="d-flex align-items-center">
                    <h6 class="fs--1 text-500 mb-0">
                      {{ formatToCurrency(weekly_analysis.void_orders) }}
                    </h6>
                    <h6 class="fs--2 ps-3 mb-0 text-success">
                      <span class="me-1 fas fa-caret-up"></span
                      >{{
                        weekly_analysis.void_orders > 0
                          ? Math.floor(
                              (weekly_analysis.void_orders /
                                weekly_analysis.total_orders) *
                                100
                            )
                          : 0
                      }}%
                    </h6>
                  </div>
                </div>
                <div
                  class="col-6 col-md-4 border-200 border-md-200 border-bottom border-md-bottom-0 border-md-end pt-4 pb-md-0 ps-3 ps-md-0"
                >
                  <h6 class="pb-1 text-700">Gifts</h6>
                  <p class="font-sans-serif lh-1 mb-1 fs-2">
                    {{ weekly_analysis.total_orders }}
                  </p>
                  <div class="d-flex align-items-center">
                    <h6 class="fs--1 text-500 mb-0">
                      {{ formatToCurrency(weekly_analysis.gift_orders_total) }}
                    </h6>
                    <h6 class="fs--2 ps-3 mb-0 text-danger">
                      <span class="me-1 fas fa-caret-up"></span
                      >{{
                        weekly_analysis.gift_orders > 0
                          ? Math.floor(
                              (weekly_analysis.gift_orders /
                                weekly_analysis.total_orders) *
                                100
                            )
                          : 0
                      }}%
                    </h6>
                  </div>
                </div>
                <div
                  class="col-6 col-md-4 border-200 border-md-bottom-0 border-end pt-4 pb-md-0 ps-md-3"
                >
                  <h6 class="pb-1 text-700">Voided Orders</h6>
                  <p class="font-sans-serif lh-1 mb-1 fs-2">
                    {{ weekly_analysis.void_orders }}
                  </p>
                  <div class="d-flex align-items-center">
                    <h6 class="fs--1 text-500 mb-0">
                      {{ formatToCurrency(weekly_analysis.void_orders_total) }}
                    </h6>
                    <h6 class="fs--2 ps-3 mb-0 text-success">
                      <span class="me-1 fas fa-caret-up"></span
                      >{{
                        weekly_analysis.void_orders > 0
                          ? Math.floor(
                              (weekly_analysis.void_orders /
                                weekly_analysis.total_orders) *
                                100
                            )
                          : 0
                      }}%
                    </h6>
                  </div>
                </div>
                <div class="col-6 col-md-4 pb-0 pt-4 ps-3">
                  <h6 class="pb-1 text-700">Processing</h6>
                  <p class="font-sans-serif lh-1 mb-1 fs-2">861</p>
                  <div class="d-flex align-items-center">
                    <h6 class="fs--1 text-500 mb-0">13,675</h6>
                    <h6 class="fs--2 ps-3 mb-0 text-info">
                      <span class="me-1 fas fa-caret-up"></span>21.8%
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div> -->
      </div>

      <FooterComp />
    </div>
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import mixins from "../mixins/index";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";

// echarts
import { VueEcharts } from "vue3-echarts";

export default {
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    VueEcharts,
  },
  data() {
    return {
      chart_key: 1,
      refreshed: 0,
      daily_analysis: [],
      weekly_analysis: [],
      market_share_chart_option: {
        title: {
          text: "Folder Documents",
          subtext: "No of documents in a folder",
          left: "center",
        },
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "vertical",
          left: "left",
        },
        series: [
          {
            name: "Access From",
            type: "pie",
            radius: "60%",
            data: [],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
      },
      sales_chart_option: {
        xAxis: {
          type: "category",
          data: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
        },
        yAxis: {
          type: "value",
        },
        series: [
          {
            data: [120, 200, 150, 80, 70, 110, 130],
            type: "bar",
            showBackground: true,
            backgroundStyle: {
              color: "rgba(180, 180, 180, 0.2)",
            },
          },
        ],
      },
    };
  },
  watch: {
    weekly_analysis: {
      handler(newVal, oldVal) {
        this.chart_key += 1;
      },
      deep: true,
    },
  },
  async created() {
    // this.forceReload();
    this.getDailyAnalysis();
    await this.getWeeklyAnalysis();
    this.market_share_chart_option = {
      title: {
        text: "Directory share",
        subtext: "No. of uploads by user",
        left: "center",
      },
      tooltip: {
        trigger: "item",
      },
      legend: {
        orient: "vertical",
        left: "left",
      },
      series: [
        {
          name: "Documents uploaded",
          type: "pie",
          radius: "60%",
          data: this.weekly_analysis.market_share,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: "rgba(0, 0, 0, 0.5)",
            },
          },
        },
      ],
    };
    this.sales_chart_option = {
      xAxis: {
        type: "category",
        data: this.weekly_analysis.weekly_sales_days,
      },
      yAxis: {
        type: "value",
      },
      series: [
        {
          data: this.weekly_analysis.weekly_sales,
          type: "bar",
          showBackground: true,
          backgroundStyle: {
            color: "rgba(180, 180, 180, 0.2)",
          },
        },
      ],
    };
  },
  mounted() {
    this.$forceUpdate();
    try {
      var isFluid = JSON.parse(localStorage.getItem("isFluid"));
      if (isFluid) {
        var container = document.querySelector("[data-layout]");
        container.classList.remove("container");
        container.classList.add("container-fluid");
      }
      var navbarStyle = localStorage.getItem("navbarStyle");
      if (navbarStyle && navbarStyle !== "transparent") {
        document
          .querySelector(".navbar-vertical")
          .classList.add(`navbar-${navbarStyle}`);
      }
      var navbarPosition = localStorage.getItem("navbarPosition");
      var navbarVertical = document.querySelector(".navbar-vertical");
      var navbarTopVertical = document.querySelector(".content .navbar-top");
      var navbarTop = document.querySelector(
        "[data-layout] .navbar-top:not([data-double-top-nav"
      );
      var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
      var navbarTopCombo = document.querySelector(
        '.content [data-navbar-top="combo"]'
      );

      if (localStorage.getItem("navbarPosition") === "double-top") {
        document.documentElement.classList.toggle("double-top-nav-layout");
      }

      if (navbarPosition === "top") {
        navbarTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTopCombo.remove(navbarTopCombo);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "combo") {
        navbarVertical.removeAttribute("style");
        navbarTopCombo.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarTopVertical.remove(navbarTopVertical);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "double-top") {
        navbarDoubleTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTop.remove(navbarTop);
        navbarTopCombo.remove(navbarTopCombo);
      } else {
        navbarVertical.removeAttribute("style");
        navbarTopVertical.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarDoubleTop.remove(navbarDoubleTop);
        navbarTopCombo.remove(navbarTopCombo);
      }
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    goToCustomers() {
      this.$router.push("/customers");
    },
    goToOrders() {
      this.$router.push("/pos-open-order");
    },
    goToMenuItems() {
      this.$router.push("/menu_item.index");
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
