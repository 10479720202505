// Data sevice
import axios from 'axios'
import authHeader from './auth-header'

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class CartService {
    getAll() {
        return axios.get(VUE_APP_BASE_URL + 'menu-item', { headers: authHeader() })
    }
    byOrder(id) {
        return axios.get(VUE_APP_BASE_URL + 'menu-item-byorder/' + id, { headers: authHeader() })
    }
    getTables() {
        return axios.get(VUE_APP_BASE_URL + 'table', { headers: authHeader() })
    }
    store(resource) {
        return axios.post(VUE_APP_BASE_URL + 'product', resource, { headers: authHeader() })
    }
    update(resource) {
        return axios.put(VUE_APP_BASE_URL + 'product/' + resource.id, resource, { headers: authHeader() })
    }
    delete(resource) {
        return axios.delete(VUE_APP_BASE_URL + 'product/' + resource.id, { headers: authHeader() })
    }
    getCategories() {
        return axios.get(VUE_APP_BASE_URL + 'product-category', { headers: authHeader() })
    }
}

export default new CartService()