<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />
      <div
        class="card mb-3"
        id="ordersTable"
        data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
      >
        <div class="card-header">
          <div class="row flex-between-center border-bottom pb-1">
            <div class="col-4 col-sm-auto align-items-center pe-0">
              <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                <i class="fa fa-shopping-cart text-primary"></i>
                Folders
              </h5>
              <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                Manage all available Folders
              </p>
            </div>
            <div class="col-8 col-sm-auto ms-auto text-end ps-0">
              <div class="d-none" id="orders-bulk-actions">
                <div class="d-flex">
                  <select
                    class="form-select form-select-sm"
                    aria-label="Bulk actions"
                  >
                    <option selected="">Bulk actions</option>
                    <option value="Refund">Refund</option>
                    <option value="Delete">Delete</option>
                    <option value="Archive">Archive</option></select
                  ><button
                    class="btn btn-falcon-default btn-sm ms-2"
                    type="button"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div id="orders-actions" class="d-flex">
                <div class="search-box h1">
                  <input
                    class="form-control search-input fuzzy-search"
                    type="text"
                    placeholder="Search..."
                    aria-label="Search"
                    v-model="searchValue"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </div>

                <button
                  class="btn btn-primary btn-sm ms-2 rounded-pil h1"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-modal"
                  @click="addNew"
                >
                  <span class="fas fa-plus" data-fa-transform=""></span
                  ><span class="d-none d-sm-inline-block ms-1">New</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body px-3 py-0">
          <!-- Menu items table  -->
          <EasyDataTable
            v-if="theme == 'dark'"
            id="tour-step-1"
            table-class-name="dark-table"
            alternating
            buttons-pagination
            :headers="headers"
            :items="resources"
            :search-field="searchField"
            :search-value="searchValue"
            :loading="loading"
          >
            <template #loading>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #item-NAME="item">
              <div class="my-2">
                <strong>{{ item.NAME.toUpperCase() }}</strong>
                <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
              </div>
            </template>
            <template #item-created_at="item">
              <div class="my-2">
                {{ formatDateHuman(item.CREATED_AT) }}
              </div>
            </template>
            <template #item-action="item">
              <div class="dropdown font-sans-serif position-static">
                <button
                  class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                  type="button"
                  id="order-dropdown-0"
                  data-bs-toggle="dropdown"
                  data-boundary="viewport"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="fas fa-ellipsis-h fs--1"></span>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end border py-0"
                  aria-labelledby="order-dropdown-0"
                >
                  <div class="py-2">
                    <a
                      class="dropdown-item"
                      role="button"
                      @click="editItem(item)"
                      >Edit</a
                    >

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item text-danger"
                      role="button"
                      @click="deleteRecource(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
            </template>
          </EasyDataTable>
          <EasyDataTable
            v-else
            id="tour-step-1"
            table-class-name="customize-table"
            alternating
            buttons-pagination
            :headers="headers"
            :items="resources"
            :search-field="searchField"
            :search-value="searchValue"
            :loading="loading"
          >
            <template #loading>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #item-order_types="item">
              <span v-for="(item, i) in item.order_types" :key="i" class="btn badge-soft-primary fs--1 m-1 p-1 d-flex">{{item.NAME}}</span>
            </template>
            <template #item-NAME="item">
              <div class="my-2">
                <strong>{{ item.NAME.toUpperCase() }}</strong>
                <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
              </div>
            </template>
            <template #item-created_at="item">
              <div class="my-2">
                {{ formatDateHuman(item.CREATED_AT) }}
              </div>
            </template>
            <template #item-cat_button="item">
              <div class="my-2">
                <button
                  v-if="item.BTN_COLOR"
                  @click="setColor(item)"
                  class="badge badge-sm text-white"
                  role="button"
                  :style="`background-color:${item.BTN_COLOR} !important;`"
                >
                  {{ item.NAME }}
                </button>
              </div>
            </template>

            <template #item-action="item">
              <div class="dropdown font-sans-serif position-static">
                <button
                  class="btn btn-primary dropdown-toggle"
                  type="button"
                  id="order-dropdown-0"
                  data-bs-toggle="dropdown"
                  data-boundary="viewport"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="fas fa-ellipsis-h fs--1"></span>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end border py-0"
                  aria-labelledby="order-dropdown-0"
                >
                  <div class="py-2">
                    <a
                      class="dropdown-item"
                      role="button"
                      @click="editItem(item)"
                      data-bs-toggle="modal"
                      data-bs-target="#edit-modal"
                      >Edit</a
                    >

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item text-danger"
                      role="button"
                      @click="deleteRecource(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
            </template>
          </EasyDataTable>
          <!-- End Menu items table  -->
        </div>
        <div class="card-footer">
          <!-- footer content  -->
        </div>
      </div>
      <FooterComp />

      <!-- Start edit modal  -->
      <div
        class="modal fade"
        id="edit-modal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="edit-modalLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Edit category</h4>
              </div>
              <div class="row my-3 mx-2">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="d-flex">
                    <div class="row">
                      <div class="position-relative">
                        <hr />
                        <div class="divider-content-center">
                          Mandatory fields
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.NAME"
                          :class="
                            v$.formData.NAME.$dirty && v$.formData.NAME.$invalid
                              ? `is-invalid`
                              : v$.formData.NAME.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.NAME.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Translated name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.TRANSLATED_NAME"
                          :class="
                            v$.formData.TRANSLATED_NAME.$dirty &&
                            v$.formData.TRANSLATED_NAME.$invalid
                              ? `is-invalid`
                              : v$.formData.TRANSLATED_NAME.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.TRANSLATED_NAME.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                  <div class="pt-2">
                    <div class="position-relative">
                      <hr />
                      <div class="divider-content-center">Optional fields</div>
                    </div>
                    <div class="row">
                      <div class="mb-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Sort order</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.SORT_ORDER"
                        />
                      </div>
                      <div class="mb-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Visibility</label
                        >
                        <ul class="nav flex-lg-column fs--1">
                          <li class="nav-item me-2 me-lg-0">
                            <a
                              @click="toggleActive"
                              class="nav-link nav-link-card-details"
                              role="button"
                              :class="
                                formData.VISIBLE ? `bg-success text-white` : ``
                              "
                            >
                              <span
                                class="fa me-2"
                                :class="
                                  formData.VISIBLE == true
                                    ? 'fa-check-square'
                                    : 'fa-close'
                                "
                              ></span
                              >Visible?
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Location</div>
                  </div>
                  <div class="overflow-auto" style="height: 200px !important">
                    <ul
                      class="nav flex-lg-column fs--1"
                      :key="formData.BTN_COLOR"
                    >
                      <li
                        v-for="(item, i) in order_types"
                        :key="i"
                        class="nav-item me-2 me-lg-0"
                      >
                        <a
                          v-if="
                            formData.order_type_ids &&
                            formData.order_type_ids.includes(item.ID)
                          "
                          @click="removeFromOrdertype(item.ID)"
                          class="bg-success text-white nav-link nav-link-card-details"
                          role="button"
                          ><span class="fa fa-check me-2"></span>
                          {{ item.NAME }}
                        </a>
                        <a
                          v-else
                          @click="addToOrdertype(item.ID)"
                          class="nav-link nav-link-card-details"
                          role="button"
                          ><span class="fa fa-check me-2"></span>
                          {{ item.NAME }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Button Color</div>
                  </div>
                  <div class="overflow-auto" style="height: 200px !important">
                    <ul
                      class="nav flex-lg-column fs--1"
                      :key="formData.BTN_COLOR"
                    >
                      <li
                        class="nav-item me-2 me-lg-0"
                        v-for="(item, i) in colors"
                        :key="i"
                      >
                        <a
                          @click="setColor(item)"
                          class="nav-link nav-link-card-details text-white"
                          role="button"
                          :style="`background-color:${item} !important;`"
                          >{{ item
                          }}<span
                            v-if="item == formData.BTN_COLOR"
                            class="fa fa-check ms-4 text-white"
                          ></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary ms-2 rounded-pil h1 d-block"
                type="button"
                @click="updateResource()"
              >
                <i class="fa-solid fa-square-check"></i>
                <span class="d-none d-sm-inline-block ms-1">Update</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->

      <!-- Start add modal  -->
      <div
        class="modal fade"
        id="add-modal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">New category</h4>
              </div>
              <div class="row my-3 mx-2">
                <div class="col-sm-12 col-md-6 col-lg-6 col-xl-6">
                  <div class="d-flex">
                    <div class="row">
                      <div class="position-relative">
                        <hr />
                        <div class="divider-content-center">
                          Mandatory fields
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="recipient-name"
                          >Name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.NAME"
                          :class="
                            v$.formData.NAME.$dirty && v$.formData.NAME.$invalid
                              ? `is-invalid`
                              : v$.formData.NAME.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.NAME.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                      <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Translated name</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="text"
                          v-model="formData.TRANSLATED_NAME"
                          :class="
                            v$.formData.TRANSLATED_NAME.$dirty &&
                            v$.formData.TRANSLATED_NAME.$invalid
                              ? `is-invalid`
                              : v$.formData.TRANSLATED_NAME.$dirty
                              ? `is-valid`
                              : ``
                          "
                        />
                        <div
                          v-for="error of v$.formData.TRANSLATED_NAME.$errors"
                          :key="error.$uid"
                          class="invalid-feedback"
                          style="color: red"
                        >
                          This field is invalid
                        </div>
                      </div>
                    </div>
                    <hr class="my-2" />
                  </div>
                  <div class="pt-2">
                    <div class="position-relative">
                      <hr />
                      <div class="divider-content-center">Optional fields</div>
                    </div>
                    <div class="row">
                      <div class="mb-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Sort order</label
                        >
                        <input
                          class="form-control"
                          id="recipient-name"
                          type="number"
                          v-model="formData.SORT_ORDER"
                        />
                      </div>
                      <div class="mb-6 col-sm-6 col-md-6 col-lg-6 col-xl-6">
                        <label class="col-form-label" for="message-text"
                          >Visibility</label
                        >
                        <ul class="nav flex-lg-column fs--1">
                          <li class="nav-item me-2 me-lg-0">
                            <a
                              @click="toggleActive"
                              class="nav-link nav-link-card-details"
                              role="button"
                              :class="
                                formData.VISIBLE ? `bg-success text-white` : ``
                              "
                            >
                              <span
                                class="fa me-2"
                                :class="
                                  formData.VISIBLE == true
                                    ? 'fa-check-square'
                                    : 'fa-close'
                                "
                              ></span
                              >Visible?
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Location</div>
                  </div>
                  <div class="overflow-auto" style="height: 200px !important">
                    <ul
                      class="nav flex-lg-column fs--1"
                      :key="formData.BTN_COLOR"
                    >
                      <li
                        v-for="(item, i) in order_types"
                        :key="i"
                        class="nav-item me-2 me-lg-0"
                      >
                        <a
                          v-if="
                            formData.order_type_ids &&
                            formData.order_type_ids.includes(item.ID)
                          "
                          @click="removeFromOrdertype(item.ID)"
                          class="bg-success text-white nav-link nav-link-card-details"
                          role="button"
                          ><span class="fa fa-check me-2"></span>
                          {{ item.NAME }}
                        </a>
                        <a
                          v-else
                          @click="addToOrdertype(item.ID)"
                          class="nav-link nav-link-card-details"
                          role="button"
                          ><span class="fa fa-check me-2"></span>
                          {{ item.NAME }}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <div class="position-relative">
                    <hr />
                    <div class="divider-content-center">Button Color</div>
                  </div>
                  <div class="overflow-auto" style="height: 200px !important">
                    <ul
                      class="nav flex-lg-column fs--1"
                      :key="formData.BTN_COLOR"
                    >
                      <li
                        class="nav-item me-2 me-lg-0"
                        v-for="(item, i) in colors"
                        :key="i"
                      >
                        <a
                          @click="setColor(item)"
                          class="nav-link nav-link-card-details text-white"
                          role="button"
                          :style="`background-color:${item} !important;`"
                          >{{ item
                          }}<span
                            v-if="item == formData.BTN_COLOR"
                            class="fa fa-check ms-4 text-white"
                          ></span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-primary ms-2 rounded-pil h1 d-block"
                type="button"
                @click="saveResource()"
              >
                <i class="fa-solid fa-square-check"></i>
                <span class="d-none d-sm-inline-block ms-1">Save</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End add modal  -->
    </div>
  </div>
</template>

<style>
.modal-open {
  overflow: scroll;
}
</style>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuCategoryService from "../services/menu_category.service";
import MenuGroupService from "../services/menu_group.service";
import OrderTypeService from "../services/order_types.service";
import MenuItemSizeService from "../services/menu_item_size.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        NAME: { required },
        TRANSLATED_NAME: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      menu_item_sizes: [],
      groups: [],
      order_types: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        NAME: "",
        VISIBLE: 1,
        BEVERAGE: "",
        SORT_ORDER: "",
        BTN_COLOR: "",
        TEXT_COLOR: "#FFFFFF",
        TRANSLATED_NAME: "",
        order_types: [],
        order_type_ids: [],
        menu_groups_ids: [],
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["NAME", "TRANSLATED_NAME"],
      headers: [
        { text: "#", value: "ID", sortable: true, widh: 10 },
        { text: "Name", value: "NAME", sortable: true },
        { text: "Translated name", value: "TRANSLATED_NAME", sortable: true },
        { text: "Sort order", value: "SORT_ORDER", sortable: true },
        { text: "Button", value: "cat_button" },
        { text: "Visible In", value: "order_types" },
        { text: "Action", value: "action" },
      ],
      selected_order_types: [],
      colors: [
        "#2C7BE5",
        "#727CF5",
        "#6B5EAE",
        "#FF679B",
        "#E63757",
        "#FD7E14",
        "#F5803E",
        "#00D27A",
        "#02A8B5",
        "#27BCFD",
        "#000",
        "#748194",
        "#4D5969",
      ],
    };
  },
  created() {
    this.forceReload();
    this.getData();
    this.getAllOrderTypes();
    // this.getMenuItemSize();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log("Loading..");
    var isFluid = JSON.parse(localStorage.getItem("isFluid"));
    if (isFluid) {
      var container = document.querySelector("[data-layout]");
      container.classList.remove("container");
      container.classList.add("container-fluid");
    }
    var navbarStyle = localStorage.getItem("navbarStyle");
    if (navbarStyle && navbarStyle !== "transparent") {
      document
        .querySelector(".navbar-vertical")
        .classList.add(`navbar-${navbarStyle}`);
    }
    var navbarPosition = localStorage.getItem("navbarPosition");
    var navbarVertical = document.querySelector(".navbar-vertical");
    var navbarTopVertical = document.querySelector(".content .navbar-top");
    var navbarTop = document.querySelector(
      "[data-layout] .navbar-top:not([data-double-top-nav"
    );
    var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
    var navbarTopCombo = document.querySelector(
      '.content [data-navbar-top="combo"]'
    );

    if (localStorage.getItem("navbarPosition") === "double-top") {
      document.documentElement.classList.toggle("double-top-nav-layout");
    }

    if (navbarPosition === "top") {
      navbarTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTopCombo.remove(navbarTopCombo);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "combo") {
      navbarVertical.removeAttribute("style");
      navbarTopCombo.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarTopVertical.remove(navbarTopVertical);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "double-top") {
      navbarDoubleTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTop.remove(navbarTop);
      navbarTopCombo.remove(navbarTopCombo);
    } else {
      navbarVertical.removeAttribute("style");
      navbarTopVertical.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarDoubleTop.remove(navbarDoubleTop);
      navbarTopCombo.remove(navbarTopCombo);
    }
  },
  methods: {
    addNew() {
      this.formData = {
        NAME: "",
        VISIBLE: 1,
        BEVERAGE: "",
        SORT_ORDER: "",
        BTN_COLOR: "",
        TEXT_COLOR: "#FFFFFF",
        TRANSLATED_NAME: "",
        order_types: [],
        order_type_ids: [],
        menu_groups_ids: [],
      };
    },

    removeFromOrdertype(type_id) {
      this.formData.order_type_ids = this.formData.order_type_ids.filter(
        (el) => el != type_id
      );
      console.log(this.formData.order_type_ids);
    },
    addToOrdertype(type_id) {
      console.log(this.formData.order_type_ids);
      this.formData.order_type_ids.push(type_id);
      console.log(this.formData.order_type_ids);
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    editItem(resource) {
      console.log(resource);
      this.formData = resource;
    },
    setColor(color) {
      console.log(color);
      this.formData.BTN_COLOR = color;
    },
    groupSelected(e) {
      this.formData.GROUP_ID = e.target.value;
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.VISIBLE = !this.formData.VISIBLE;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.NAME.$touch();
      this.v$.formData.TRANSLATED_NAME.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      MenuCategoryService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            // $("#add-modal").hide();
            // $("body").removeClass("modal-open");
            // $(".modal-backdrop").remove();
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          let error_log = "";
          Object.entries(error.response.data.errors).forEach(([key, value]) => {
            error_log += `${value},`;
            console.log(value);
          });
          toast.error(error_log || error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource() {
      this.formData.TEXT_COLOR = "#FFFFFFF";
      this.$store.commit("SET_LOADING", true);
      MenuCategoryService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          toast.error(error.response.data.errors[0] || error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        MenuCategoryService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal" + resource.ID)
                .removeClass("fade")
                .hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return MenuCategoryService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
