<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />
      <div
        class="card mb-3"
        id="ordersTable"
        data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
      >
        <div class="card-header">
          <div class="row flex-between-center border-bottom pb-1">
            <div class="col-4 col-sm-auto align-items-center pe-0">
              <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                <i class="fa fa-shopping-cart text-warning"></i>
                Selling Points
              </h5>
              <p class="fs--1 fw-1 text-500 mb-0 text-nowrap">
                Manage all selling points or order types
              </p>
            </div>
            <div class="col-8 col-sm-auto ms-auto text-end ps-0">
              <div class="d-none" id="orders-bulk-actions">
                <div class="d-flex">
                  <select
                    class="form-select form-select-sm"
                    aria-label="Bulk actions"
                  >
                    <option selected="">Bulk actions</option>
                    <option value="Refund">Refund</option>
                    <option value="Delete">Delete</option>
                    <option value="Archive">Archive</option></select
                  ><button
                    class="btn btn-falcon-default btn-sm ms-2"
                    type="button"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div id="orders-actions" class="d-flex">
                <div class="search-box h1">
                  <input
                    class="form-control search-input fuzzy-search"
                    type="text"
                    placeholder="Search..."
                    aria-label="Search"
                    v-model="searchValue"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </div>
                <button
                  class="btn btn-falcon-warning ms-2 rounded-pil h2"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-modal"
                >
                  <span class="fas fa-plus" data-fa-transform=""></span
                  ><span class="d-none d-sm-inline-block ms-1"
                    >New Store</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body px-3 py-0">
          <!-- Menu items table  -->
          <EasyDataTable
            v-if="theme == 'dark'"
            id="tour-step-1"
            table-class-name="dark-table"
            alternating
            buttons-pagination
            :headers="headers"
            :items="resources"
            :search-field="searchField"
            :search-value="searchValue"
            :loading="loading"
          >
            <template #loading>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #item-NAME="item">
              <div class="my-2">
                <strong>{{ item.NAME.toUpperCase() }}</strong>
                <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
              </div>
            </template>
            <template #item-created_at="item">
              <div class="my-2">
                {{ formatDateHuman(item.CREATED_AT) }}
              </div>
            </template>

            <template #item-action="item">
              <div class="dropdown font-sans-serif position-static">
                <button
                  class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                  type="button"
                  id="order-dropdown-0"
                  data-bs-toggle="dropdown"
                  data-boundary="viewport"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="fas fa-ellipsis-h fs--1"></span>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end border py-0"
                  aria-labelledby="order-dropdown-0"
                >
                  <div class="py-2">
                    <a
                      class="dropdown-item"
                      role="button"
                      data-bs-toggle="modal"
                      :data-bs-target="'#edit-modal' + item.id"
                      >Edit</a
                    >

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item text-danger"
                      role="button"
                      @click="deleteRecource(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
            </template>
          </EasyDataTable>
          <EasyDataTable
            v-else
            id="tour-step-1"
            table-class-name="customize-table"
            alternating
            buttons-pagination
            :headers="headers"
            :items="resources"
            :search-field="searchField"
            :search-value="searchValue"
            :loading="loading"
          >
            <template #loading>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #item-NAME="item">
              <div class="my-2">
                <strong>{{ item.NAME.toUpperCase() }}</strong>
                <p class="mb-0 text-500 text-wrap">{{ item.email }}</p>
              </div>
            </template>
            <template #item-created_at="item">
              <div class="my-2">
                {{ formatDateHuman(item.CREATED_AT) }}
              </div>
            </template>

            <template #item-action="item">
              <div class="dropdown font-sans-serif position-static">
                <button
                  class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                  type="button"
                  id="order-dropdown-0"
                  data-bs-toggle="dropdown"
                  data-boundary="viewport"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="fas fa-ellipsis-h fs--1"></span>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end border py-0"
                  aria-labelledby="order-dropdown-0"
                >
                  <div class="py-2">
                    <a
                      class="dropdown-item"
                      role="button"
                      data-bs-toggle="modal"
                      :data-bs-target="'#edit-modal' + item.ID"
                      >Edit</a
                    >

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item text-danger"
                      role="button"
                      @click="deleteRecource(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
            </template>
          </EasyDataTable>
          <!-- End Menu items table  -->
        </div>
        <div class="card-footer">
          <!-- footer content  -->
        </div>
      </div>
      <FooterComp />

      <!-- Start edit modal  -->
      <div v-for="(resource, i) in resources" :key="i">
        <div
          class="modal fade"
          :id="'edit-modal' + resource.ID"
          data-bs-keyboard="false"
          data-bs-backdrop="static"
          tabindex="-1"
          :aria-labelledby="'edit-modal' + resource.ID + 'Label'"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg mt-6" role="document">
            <div class="modal-content border-0">
              <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                <button
                  class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body p-0">
                <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                  <h4 class="mb-1" id="staticBackdropLabel">Edit Products</h4>
                </div>
                <div class="row my-3 mx-2">
                  <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i class="fas fa-circle fa-stack-2x text-200"></i
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <div class="row">
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="recipient-name"
                              >Name</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.NAME"
                              v-model="formData.NAME"
                              :class="
                                v$.formData.NAME.$dirty &&
                                v$.formData.NAME.$invalid
                                  ? `is-invalid`
                                  : v$.formData.NAME.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.NAME.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>

                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="message-text"
                              >Select Group</label
                            >
                            <select
                              class="form-select"
                              id="recipient-name"
                              type="text"
                              @change="groupSelected"
                              :class="
                                v$.formData.GROUP_ID.$dirty &&
                                v$.formData.GROUP_ID.$invalid
                                  ? `is-invalid`
                                  : v$.formData.GROUP_ID.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            >
                              <option value="">Group...</option>
                              <option
                                v-for="(item, i) in groups"
                                :key="i"
                                :value="item.ID"
                                :selected="item.ID == resource.ID"
                              >
                                {{ item.NAME }}
                              </option>
                            </select>
                            <div
                              v-for="error of v$.formData.GROUP_ID.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>

                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Buying Price</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.BUY_PRICE"
                              v-model="formData.BUY_PRICE"
                              :class="
                                v$.formData.BUY_PRICE.$dirty &&
                                v$.formData.BUY_PRICE.$invalid
                                  ? `is-invalid`
                                  : v$.formData.BUY_PRICE.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.BUY_PRICE.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Selling Price</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.PRICE"
                              v-model="formData.PRICE"
                              :class="
                                v$.formData.PRICE.$dirty &&
                                v$.formData.PRICE.$invalid
                                  ? `is-invalid`
                                  : v$.formData.PRICE.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.PRICE.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Stock Quantity</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.ge 10"
                              :placeholder="resource.STOCK_AMOUNT"
                              v-model="formData.STOCK_AMOUNT"
                              :class="
                                v$.formData.STOCK_AMOUNT.$dirty &&
                                v$.formData.STOCK_AMOUNT.$invalid
                                  ? `is-invalid`
                                  : v$.formData.STOCK_AMOUNT.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.STOCK_AMOUNT.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                        </div>
                        <hr class="my-2" />
                      </div>
                    </div>
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i class="fas fa-circle fa-stack-2x text-200"></i
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <h5 class="mb-2 fs-0">Optional fields</h5>
                        <div class="row">
                          <!-- <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="recipient-name"
                              >Photo</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="file"
                            />
                          </div> -->

                          <div
                            class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          >
                            <label class="col-form-label" for="message-text"
                              >Barcode</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              :placeholder="resource.BARCODE"
                              v-model="formData.BARCODE"
                            />
                          </div>
                          <label class="col-form-label" for="message-text"
                            >Description</label
                          >
                          <p class="text-word-break fs--1">
                            <textarea
                              v-model="formData.DESCRIPTION"
                              :placeholder="resource.DESCRIPTION"
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                            ></textarea>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                    <ul class="nav flex-lg-column fs--1">
                      <li class="nav-item me-2 me-lg-0 my-2">
                        <a
                          @click="toggleActive"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="resource.VISIBLE ? `bg-info text-white` : ``"
                          ><span class="fa fa-check me-2"></span>Active?
                        </a>
                      </li>
                      <li class="nav-item me-2 me-lg-0 my-2">
                        <select
                          class="form-select nav-link nav-link-card-details"
                          id="recipient-name"
                          type="text"
                          v-model="formData.UNIT_NAME"
                        >
                          <option value="">Sizes...</option>
                          <option
                            v-for="(item, i) in menu_item_sizes"
                            :key="i"
                            :value="item.NAME"
                            :selected="item.NAME == resource.UNIT_NAME"
                          >
                            {{ item.NAME }}
                          </option>
                        </select>
                      </li>

                      <br />
                      <hr />
                      <br />
                      <br />
                      <li class="nav-item me-2 me-lg-0 mt-8">
                        <a
                          class="nav-link nav-link-card-details bg-danger text-white"
                          role="button"
                          @click="deleteRecource(resource)"
                          ><span class="fas fa-paperclip me-2"></span>Delete</a
                        >
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
                  type="button"
                  @click="updateResource(resource)"
                >
                  <span class="fa fa-save me-2" data-fa-transform=""></span
                  ><span class="d-none d-sm-inline-block ms-1"
                    >Update product</span
                  >
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End edit modal  -->

      <!-- Start add modal  -->
      <div
        class="modal fade"
        id="add-modal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div v-if="resources.length<1" class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Add New Store</h4>
              </div>
              <div class="p-4">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i class="fas fa-circle fa-stack-2x text-200"></i
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <div class="row">
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="recipient-name"
                              >Name</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.g UGALI BEEF"
                              v-model="formData.NAME"
                              :class="
                                v$.formData.NAME.$dirty &&
                                v$.formData.NAME.$invalid
                                  ? `is-invalid`
                                  : v$.formData.NAME.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.NAME.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>

                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="message-text"
                              >Select Group</label
                            >
                            <select
                              class="form-select"
                              id="recipient-name"
                              type="text"
                              @change="groupSelected"
                              :class="
                                v$.formData.GROUP_ID.$dirty &&
                                v$.formData.GROUP_ID.$invalid
                                  ? `is-invalid`
                                  : v$.formData.GROUP_ID.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            >
                              <option value="">Group...</option>
                              <option
                                v-for="(item, i) in groups"
                                :key="i"
                                :value="item.ID"
                              >
                                {{ item.NAME }}
                              </option>
                            </select>
                            <div
                              v-for="error of v$.formData.GROUP_ID.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>

                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Buying Price</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.ge 1200"
                              v-model="formData.BUY_PRICE"
                              :class="
                                v$.formData.BUY_PRICE.$dirty &&
                                v$.formData.BUY_PRICE.$invalid
                                  ? `is-invalid`
                                  : v$.formData.BUY_PRICE.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.BUY_PRICE.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Selling Price</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.ge 1200"
                              v-model="formData.PRICE"
                              :class="
                                v$.formData.PRICE.$dirty &&
                                v$.formData.PRICE.$invalid
                                  ? `is-invalid`
                                  : v$.formData.PRICE.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.PRICE.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Stock Quantity</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.ge 10"
                              v-model="formData.STOCK_AMOUNT"
                            />
                          </div>
                        </div>
                        <hr class="my-2" />
                      </div>
                    </div>
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i class="fas fa-circle fa-stack-2x text-200"></i
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <h5 class="mb-2 fs-0">Optional fields</h5>
                        <div class="row">
                          <!-- <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                          >
                            <label class="col-form-label" for="recipient-name"
                              >Photo</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="file"
                            />
                          </div> -->

                          <div
                            class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                          >
                            <label class="col-form-label" for="message-text"
                              >Barcode</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.ge 5465467874"
                              v-model="formData.BARCODE"
                            />
                          </div>
                          <label class="col-form-label" for="message-text"
                            >Description</label
                          >
                          <p class="text-word-break fs--1">
                            <textarea
                              v-model="formData.DESCRIPTION"
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              :placeholder="formData.DESCRIPTION"
                            ></textarea>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                    <ul class="nav flex-lg-column fs--1">
                      <li class="nav-item me-2 me-lg-0 my-2">
                        <a
                          @click="toggleActive"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="formData.VISIBLE ? `bg-info text-white` : ``"
                          ><span class="fa fa-check me-2"></span>Active?
                        </a>
                      </li>

                      <li class="nav-item me-2 me-lg-0 my-2">
                        <select
                          class="form-select nav-link nav-link-card-details"
                          id="recipient-name"
                          type="text"
                          v-model="formData.UNIT_NAME"
                        >
                          <option value="">Sizes...</option>
                          <option
                            v-for="(item, i) in menu_item_sizes"
                            :key="i"
                            :value="item.NAME"
                          >
                            {{ item.NAME }}
                          </option>
                        </select>
                      </li>

                      <br />
                      <hr />
                      <br />
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="modal-footer">
              <button
                class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
                type="button"
                @click="saveResource"
              >
                <span class="fa fa-save me-2" data-fa-transform=""></span
                ><span class="d-none d-sm-inline-block ms-1">Save product</span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <!-- End add modal  -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import "flatpickr/dist/flatpickr.css";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import flatPickr from "vue-flatpickr-component";
import EasyDataTable from "vue3-easy-data-table";
import CartService from "../services/cart.service";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import MenuGroupService from "../services/menu_group.service";
import MenuItemSizeService from "../services/menu_item_size.service";
import OrderTypeService from "../services/order_types.service";

const now = new Date();

export default {
  mixins: [mixins],
  name: "CustomersView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        NAME: { required },
        PRICE: { required },
        GROUP_ID: { required },
        BUY_PRICE: { required },
        STOCK_AMOUNT: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      order_types: [],
      selected_category: "",
      menu_item_sizes: [],
      productCategories: [],
      groups: [],
      searchValue: "",
      table_keys: 1,
      formData: {
        GROUP_ID: "",
        ACTIVE: 0,
        VISIBLE: 1,
        UNIT_NAME: "",
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["ID", "NAME", "PRICE"],
      headers: [
        { text: "#", value: "ID", sortable: true, widh: 10 },
        { text: "Name", value: "NAME", sortable: true },
        { text: "Properties", value: "PROPERTIES", sortable: true },
        { text: "Units", value: "UNIT_NAME", sortable: true },
        { text: "B.Price", value: "BUY_PRICE", sortable: true },
        { text: "S.Price", value: "PRICE", sortable: true },
        { text: "Details", value: "DESCRIPTION", sortable: true },
        { text: "Barcode", value: "BARCODE", sortable: true },
        { text: "Stock Amount", value: "STOCK_AMOUNT", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  async created() {
    this.forceReload();
    this.getData();
  },
  watch: {
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
    theme() {
      return localStorage.getItem("theme");
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log("Loading..");
    var isFluid = JSON.parse(localStorage.getItem("isFluid"));
    if (isFluid) {
      var container = document.querySelector("[data-layout]");
      container.classList.remove("container");
      container.classList.add("container-fluid");
    }
    var navbarStyle = localStorage.getItem("navbarStyle");
    if (navbarStyle && navbarStyle !== "transparent") {
      document
        .querySelector(".navbar-vertical")
        .classList.add(`navbar-${navbarStyle}`);
    }
    var navbarPosition = localStorage.getItem("navbarPosition");
    var navbarVertical = document.querySelector(".navbar-vertical");
    var navbarTopVertical = document.querySelector(".content .navbar-top");
    var navbarTop = document.querySelector(
      "[data-layout] .navbar-top:not([data-double-top-nav"
    );
    var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
    var navbarTopCombo = document.querySelector(
      '.content [data-navbar-top="combo"]'
    );

    if (localStorage.getItem("navbarPosition") === "double-top") {
      document.documentElement.classList.toggle("double-top-nav-layout");
    }

    if (navbarPosition === "top") {
      navbarTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTopCombo.remove(navbarTopCombo);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "combo") {
      navbarVertical.removeAttribute("style");
      navbarTopCombo.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarTopVertical.remove(navbarTopVertical);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "double-top") {
      navbarDoubleTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTop.remove(navbarTop);
      navbarTopCombo.remove(navbarTopCombo);
    } else {
      navbarVertical.removeAttribute("style");
      navbarTopVertical.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarDoubleTop.remove(navbarDoubleTop);
      navbarTopCombo.remove(navbarTopCombo);
    }
  },
  methods: {
    async selectOrderType(e) {
      this.$store.commit("SET_LOADING", true);
      await this.getItemsByOrder(e.target.value);
      this.$store.commit("SET_LOADING", false);
    },
    categorySelected(e) {
      console.log(e);
      this.getItemsByOrder(e.target.value);
    },
    sortItems(list) {
      return list.sort((a, b) => (a.NAME > b.NAME ? 1 : -1));
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;

            this.getItemsByOrder(response.data.data.at(0).ID);
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getItemsByOrder(item_id) {
      this.$store.commit("SET_LOADING", true);
      return CartService.byOrder(item_id).then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = this.sortItems(response.data.data);
            this.$store.commit("SET_LOADING", false);
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    groupSelected(e) {
      this.formData.GROUP_ID = e.target.value;
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getGroups() {
      return MenuGroupService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.NAME.$touch();
      this.v$.formData.PRICE.$touch();
      this.v$.formData.GROUP_ID.$touch();
      this.v$.formData.BUY_PRICE.$touch();
      this.v$.formData.STOCK_AMOUNT.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      OrderTypeService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            // Clear modals
            $("#add-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource(resource) {
      this.$store.commit("SET_LOADING", true);
      this.formData.ID = resource.ID;
      if (!this.formData.GROUP_ID) {
        this.formData.GROUP_ID = resource.GROUP_ID;
      }
      if (!this.formData.VISIBLE) {
        this.formData.VISIBLE = resource.VISIBLE;
      }
      if (!this.formData.PRICE) {
        this.formData.PRICE = resource.PRICE;
      }
      if (!this.formData.BUY_PRICE) {
        this.formData.BUY_PRICE = resource.BUY_PRICE;
      }
      if (!this.formData.BARCODE) {
        this.formData.BARCODE = resource.BARCODE;
      }
      if (!this.formData.UNIT_NAME) {
        this.formData.UNIT_NAME = resource.UNIT_NAME;
      }
      if (!this.formData.DESCRIPTION) {
        this.formData.DESCRIPTION = resource.DESCRIPTION;
      }
      if (!this.formData.NAME) {
        this.formData.NAME = resource.NAME;
      }
      OrderTypeService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            this.formData = {
              GROUP_ID: "",
              ACTIVE: 0,
              VISIBLE: 1,
              UNIT_NAME: "",
              BARCODE: "",
              NAME: "",
              DESCRIPTION: "",
              BUY_PRICE: "",
              PRICE: "",
              STOCK_AMOUNT: "",
            };
            // Clear modals
            $("#edit-modal" + resource.ID)
              .removeClass("fade")
              .hide();
            $(".modal-backdrop").remove();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        this.$store.commit("SET_LOADING", true);
        OrderTypeService.delete(resource).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal" + resource.ID)
                .removeClass("fade")
                .hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
