<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />

      <div class="row mb-2">
        <div class="col ms-n2 pe-1">
          <div class="card bg-100 shadow-none border">
            <div class="row gx-0 flex-between-center">
              <div class="col-sm-auto d-flex align-items-center">
                <img
                  class="ms-n2"
                  src="assets/img/illustrations/crm-bar-chart.png"
                  alt=""
                  width="90"
                />
                <div>
                  <h6 class="text-primary fs--1 mb-0">Manage All</h6>
                  <h4 class="text-warning fw-bold mb-0">Documents</h4>
                </div>
                <img
                  class="ms-n4 d-md-none d-lg-block"
                  src="assets/img/illustrations/crm-line-chart.png"
                  alt=""
                  width="150"
                />
              </div>
              <div class="col-md-auto p-3">
                <form class="row align-items-center g-3">
                  <div class="col-auto">
                    <div class="btn-group mb-2 mb-md-0">
                      <button
                        class="bg-soft-success nav-link nav-link-card-details py-3 mx-2 border dropdown-toggle px-2"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="width: 150px"
                      >
                        {{ order_type.NAME || "Select Location" }}
                      </button>
                      <div class="dropdown-menu">
                        <a
                          class="dropdown-item nav-item me-2 me-lg-0 d-block"
                          v-for="(item, i) in order_types"
                          :key="i"
                          @click="selectOrderType(item)"
                        >
                          <a
                            class="nav-link nav-link-card-details py-3 px-2 border text-center"
                            role="button"
                            :class="
                              item.ID == order_type.ID
                                ? `bg-success text-white`
                                : ``
                            "
                            >{{ item.NAME }}
                          </a>
                        </a>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row g-3 mb-2">
        <div class="col-12">
          <div class="row g-3">
            <div
              class="card col-12 col-md-2 col-sm-6 col-xs-6 scrollbar list py-3 px-1 mb-3"
              style="max-height: 100%"
            >
              <h6
                class="border-bottom mb-1 dropdown-header mt-lg-0 text-uppercase px-card fs--4 pt-0 pb-2"
              >
                Groups
              </h6>
              <ul class="nav flex-lg-column fs--1">
                <li class="nav-item me-2 me-lg-0 d-block">
                  <a
                    class="nav-link nav-link-card-details py-3 mx-2 border border-warning text-warning text-center"
                    role="button"
                    @click="toggleGroup(true)"
                  >
                    <span v-show="!add_group">
                      <i class="fa fa-wrench faa-wrench"></i> Add New
                    </span>
                    <input
                      ref="add_group_key"
                      v-show="add_group"
                      type="text"
                      @change="addGroup"
                      @blur="toggleGroup(false)"
                      placeholder="Name..."
                      v-model="formData.group_name"
                      class="form-control"
                    />
                  </a>
                </li>
                <li
                  v-for="(item, i) in groups"
                  class="nav-item me-2 me-lg-0 d-block"
                  @click="getMenuItemsByGroup(item.ID)"
                >
                  <a
                    class="nav-link nav-link-card-details py-3 mx-2 border"
                    role="button"
                    :class="
                      selected_group == item.ID ? `bg-primary text-white` : ``
                    "
                    >{{ item.NAME }}
                  </a>
                </li>
              </ul>
            </div>
            <!-- Start goups  -->
            <div class="col-12 col-lg-8 col-md-8 col-sm-4 col-xs-4">
              <div class="card mb-2">
                <div class="card-body">
                  <div class="row flex-between-center">
                    <div class="col-sm-auto mb-2 mb-sm-0">
                      <h6 class="mb-0">Showing {{ items.length }} Products</h6>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Edn goups  -->
              <div class="card mb-3">
                <div class="card-body">
                  <div class="row">
                    <div class="col-12 col-md-12 col-mlg-12 col-xl-12">
                      <div class="row">
                        <div
                          class="col-4 col-md-2 col-lg-2 border rounded-1 d-flex flex-column justify-content-between border-warning"
                          data-bs-toggle="modal"
                          data-bs-target="#product-add-modal"
                        >
                          <div class="overflow-hidden">
                            <div
                              class="position-relative rounded-top overflow-hidden"
                            >
                              <a class="d-block text-center" role="button"
                                ><img
                                  class="img-fluid rounded-top"
                                  src="assets/img/no-image.jpg"
                                  alt="Product Image"
                                  width="100"
                                />
                                <i class="fa fa-plus text-warning"></i>
                              </a>
                            </div>
                            <div class="p-1 text-center">
                              <h5 class="fs--1">
                                <a class="text-dark" role="button"> Add </a>
                              </h5>
                              <span
                                class="fs--2 fw-bold text-warning d-block"
                                role="button"
                              >
                                New
                              </span>
                            </div>
                          </div>
                        </div>

                        <div
                          v-if="items.length > 0"
                          class="col-4 col-md-2 col-lg-2 border rounded-1 d-flex flex-column justify-content-between"
                          v-for="(item, i) in items"
                          :key="i"
                          @click="addTocart(item)"
                        >
                          <div class="overflow-hidden">
                            <div
                              class="position-relative rounded-top overflow-hidden"
                            >
                              <a class="d-block text-center" role="button"
                                ><img
                                  class="img-fluid rounded-top"
                                  src="assets/img/no-image.jpg"
                                  alt="Product Image"
                                  width="100"
                              /></a>
                            </div>
                            <div class="p-1 text-center">
                              <h5 class="fs--1">
                                <a class="text-dark" role="button">
                                  {{ truncateText(item.NAME, 15) }}
                                </a>
                              </h5>
                              <span
                                class="fs--3 fw-bold text-warning d-block"
                                role="button"
                              >
                                {{ formatToCurrency(item.PRICE) }}
                              </span>
                            </div>
                          </div>
                        </div>
                        <p v-else>No item found!</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer bg-light d-flex justify-content-center">
                  <div>Bulla</div>
                </div>
              </div>
            </div>

            <div
              class="card col-12 col-md-2 col-sm-6 col-xs-6 scrollbar list py-3 px-1 mb-3"
              style="max-height: 100%"
            >
              <h6
                class="border-bottom mb-1 dropdown-header mt-lg-0 text-uppercase px-card fs--4 pt-0 pb-2"
              >
                Categories
              </h6>
              <ul class="nav flex-lg-column fs--1">
                <li class="nav-item me-2 me-lg-0 d-block">
                  <a
                    class="nav-link nav-link-card-details py-3 mx-2 border border-warning text-warning text-center"
                    role="button"
                    @click="toggleCategory(true)"
                  >
                    <span v-show="!add_category">
                      <i class="fa fa-wrench faa-wrench"></i> Add New
                    </span>
                    <input
                      ref="add_category_key"
                      v-show="add_category"
                      type="text"
                      @change="addCategory"
                      @blur="toggleCategory(false)"
                      placeholder="Category name.."
                      v-model="formData.category_name"
                      class="form-control"
                    />
                  </a>
                </li>
                <li
                  v-for="(item, i) in productCategories"
                  :key="i"
                  @click="getGroups(item)"
                  class="nav-item me-2 me-lg-0 d-block"
                >
                  <a
                    class="nav-link nav-link-card-details py-3 mx-2 border"
                    role="button"
                    :class="
                      selected_category == item.ID
                        ? `bg-success text-white`
                        : ``
                    "
                    >{{ item.NAME }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <FooterComp />
    </div>
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start add product modal  -->
    <div
      class="modal fade"
      id="product-add-modal"
      data-bs-keyboard="false"
      data-bs-backdrop="static"
      tabindex="-1"
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg mt-6" role="document">
        <div class="modal-content border-0">
          <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
              <h4 class="mb-1" id="staticBackdropLabel">Add new product</h4>
            </div>
            <div class="p-4">
              <div class="row">
                <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <div class="row">
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="recipient-name"
                            >Name</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.g UGALI BEEF"
                            v-model="formData.NAME"
                            :class="
                              v$.formData.NAME.$dirty &&
                              v$.formData.NAME.$invalid
                                ? `is-invalid`
                                : v$.formData.NAME.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.NAME.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>

                        <div class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                          <label class="col-form-label" for="message-text"
                            >Select Group</label
                          >
                          <select
                            class="form-select"
                            id="recipient-name"
                            type="text"
                            @change="groupSelected"
                            :class="
                              v$.formData.GROUP_ID.$dirty &&
                              v$.formData.GROUP_ID.$invalid
                                ? `is-invalid`
                                : v$.formData.GROUP_ID.$dirty
                                ? `is-valid`
                                : ``
                            "
                          >
                            <option value="">Group...</option>
                            <option
                              v-for="(item, i) in groups"
                              :key="i"
                              :value="item.ID"
                              :selected="item.ID == this.selected_group"
                            >
                              {{ item.NAME }}
                            </option>
                          </select>
                          <div
                            v-for="error of v$.formData.GROUP_ID.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>

                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Buying Price</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.ge 1200"
                            v-model="formData.BUY_PRICE"
                            :class="
                              v$.formData.BUY_PRICE.$dirty &&
                              v$.formData.BUY_PRICE.$invalid
                                ? `is-invalid`
                                : v$.formData.BUY_PRICE.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.BUY_PRICE.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Selling Price</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.ge 1200"
                            v-model="formData.PRICE"
                            :class="
                              v$.formData.PRICE.$dirty &&
                              v$.formData.PRICE.$invalid
                                ? `is-invalid`
                                : v$.formData.PRICE.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.PRICE.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                        <div class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                          <label class="col-form-label" for="message-text"
                            >Stock Quantity</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.ge 10"
                            v-model="formData.STOCK_AMOUNT"
                            :class="
                              v$.formData.STOCK_AMOUNT.$dirty &&
                              v$.formData.STOCK_AMOUNT.$invalid
                                ? `is-invalid`
                                : v$.formData.STOCK_AMOUNT.$dirty
                                ? `is-valid`
                                : ``
                            "
                          />
                          <div
                            v-for="error of v$.formData.STOCK_AMOUNT.$errors"
                            :key="error.$uid"
                            class="invalid-feedback"
                            style="color: red"
                          >
                            This field is invalid
                          </div>
                        </div>
                      </div>
                      <hr class="my-2" />
                    </div>
                  </div>
                  <div class="d-flex">
                    <span class="fa-stack ms-n1 me-3"
                      ><i class="fas fa-circle fa-stack-2x text-200"></i
                      ><i
                        class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                        data-fa-transform="shrink-2"
                      ></i
                    ></span>
                    <div class="flex-1">
                      <h5 class="mb-2 fs-0">Optional fields</h5>
                      <div class="row">
                        <!-- <div
                          class="mb-3 col-sm-12 col-md-6 col-lg-6 col-xl-6"
                        >
                          <label class="col-form-label" for="recipient-name"
                            >Photo</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="file"
                          />
                        </div> -->

                        <div
                          class="mb-3 col-sm-12 col-md-12 col-lg-12 col-xl-12"
                        >
                          <label class="col-form-label" for="message-text"
                            >Barcode</label
                          >
                          <input
                            class="form-control"
                            id="recipient-name"
                            type="text"
                            placeholder="e.ge 5465467874"
                            v-model="formData.BARCODE"
                          />
                        </div>
                        <label class="col-form-label" for="message-text"
                          >Description</label
                        >
                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.DESCRIPTION"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            :placeholder="formData.DESCRIPTION"
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                  <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                  <ul class="nav flex-lg-column fs--1">
                    <li class="nav-item me-2 me-lg-0 my-2">
                      <a
                        @click="toggleActive"
                        class="nav-link nav-link-card-details"
                        role="button"
                        :class="formData.VISIBLE ? `bg-info text-white` : ``"
                        ><span class="fa fa-check me-2"></span>Active?
                      </a>
                    </li>

                    <li class="nav-item me-2 me-lg-0 my-2">
                      <select
                        class="form-select nav-link nav-link-card-details"
                        id="recipient-name"
                        type="text"
                        v-model="formData.UNIT_NAME"
                      >
                        <option value="">Sizes...</option>
                        <option
                          v-for="(item, i) in menu_item_sizes"
                          :key="i"
                          :value="item.NAME"
                        >
                          {{ item.NAME }}
                        </option>
                      </select>
                    </li>

                    <br />
                    <hr />
                    <br />
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-falcon-primary ms-2 rounded-pil h1 d-block"
              type="button"
              @click="saveResource"
            >
              <span class="fa fa-save me-2" data-fa-transform=""></span
              ><span class="d-none d-sm-inline-block ms-1">Save product</span>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End add product  -->
  </div>
</template>

<script>
import $ from "jquery";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import { useVuelidate } from "@vuelidate/core";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import CartService from "../services/cart.service";
import TablesService from "../services/tables.service";
import TicketService from "../services/ticket.service";
import { required, email } from "@vuelidate/validators";
import ProductService from "../services/product.service";
import CustomerService from "../services/customer.service";
import MenuItemsService from "../services/menu_item.service";
import MenuGroupService from "../services/menu_group.service";
import OrderTypeService from "../services/order_types.service";
import MenuCategoryService from "../services/menu_category.service";
import MenuItemSizeService from "../services/menu_item_size.service";

export default {
  mixins: [mixins],
  validations() {
    return {
      formData: {
        NAME: { required },
        PRICE: { required },
        GROUP_ID: { required },
        BUY_PRICE: { required },
        STOCK_AMOUNT: { required },
      },
    };
  },
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
  },
  data() {
    return {
      menu_item_sizes: [],
      add_group: false,
      add_category: false,
      checkout: false,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        GROUP_ID: "",
        ACTIVE: 0,
        VISIBLE: 1,
        UNIT_NAME: "",
      },
      searchKey: "",
      barcode: "",
      loading_qr: false,
      dialog: false,
      items: {},
      tables: [],
      order_types: [],
      dialogQty: false,
      itemDialog: false,
      productCategories: [],
      customers: [],
      groups: [],
      selectedProductCategory: "",
      cart: {},
      table_selection: localStorage.getItem("table") == null ? true : false,
      category_selection: false,
      order_type_selection: true,
      order_type:
        localStorage.getItem("order_type") == null
          ? {}
          : JSON.parse(localStorage.getItem("order_type")),

      selectedCategory: 0,
      selected_category: "",
      selected_category_data: {},
      selected_group: "",
      selected_table: "",
      selected_customer: "",
    };
  },
  watch: {
    selected_group(newVal, oldVal) {
      this.formData.GROUP_ID = newVal;
    },
  },
  async created() {
    this.forceReload();
    this.$store.commit("SET_LOADING", true);
    await this.getMenuCategories();
    await this.getItemsByOrder(this.productCategories.at(0));
    this.selected_category = this.productCategories.at(0).ID;
    await this.getMenuGroupsByCategory();
    await this.getMenuItemsByGroup(this.groups.at(0).ID);
    await this.getAllTables();
    this.selected_table = this.tables.at(0);
    await this.getAllOrderTypes();
    this.getCustomers();
    this.getMenuItemSize();
    this.$store.commit("SET_LOADING", false);
  },
  mounted() {
    try {
      var isFluid = JSON.parse(localStorage.getItem("isFluid"));
      if (isFluid) {
        var container = document.querySelector("[data-layout]");
        container.classList.remove("container");
        container.classList.add("container-fluid");
      }
      var navbarStyle = localStorage.getItem("navbarStyle");
      if (navbarStyle && navbarStyle !== "transparent") {
        document
          .querySelector(".navbar-vertical")
          .classList.add(`navbar-${navbarStyle}`);
      }
      var navbarPosition = localStorage.getItem("navbarPosition");
      var navbarVertical = document.querySelector(".navbar-vertical");
      var navbarTopVertical = document.querySelector(".content .navbar-top");
      var navbarTop = document.querySelector(
        "[data-layout] .navbar-top:not([data-double-top-nav"
      );
      var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
      var navbarTopCombo = document.querySelector(
        '.content [data-navbar-top="combo"]'
      );

      if (localStorage.getItem("navbarPosition") === "double-top") {
        document.documentElement.classList.toggle("double-top-nav-layout");
      }

      if (navbarPosition === "top") {
        navbarTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTopCombo.remove(navbarTopCombo);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "combo") {
        navbarVertical.removeAttribute("style");
        navbarTopCombo.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarTopVertical.remove(navbarTopVertical);
        navbarDoubleTop.remove(navbarDoubleTop);
      } else if (navbarPosition === "double-top") {
        navbarDoubleTop.removeAttribute("style");
        navbarTopVertical.remove(navbarTopVertical);
        navbarVertical.remove(navbarVertical);
        navbarTop.remove(navbarTop);
        navbarTopCombo.remove(navbarTopCombo);
      } else {
        navbarVertical.removeAttribute("style");
        navbarTopVertical.removeAttribute("style");
        navbarTop.remove(navbarTop);
        navbarDoubleTop.remove(navbarDoubleTop);
        navbarTopCombo.remove(navbarTopCombo);
      }
    } catch (error) {
      console.log(error);
    }
  },
  computed: {
    totalValue() {
      let itemArray = Object.values(this.cart);
      let totalValue = 0;
      itemArray.forEach((element) => {
        totalValue =
          totalValue + parseFloat(element.QUANTITY) * parseFloat(element.PRICE);
      });
      return parseFloat(totalValue);
    },
    cartLength() {
      let data = this.cart;
      if (data.length) {
        return data.length;
      } else {
        return "empty";
      }
    },
    cartItems() {
      let cartData = Object.values(this.cart);
      return cartData;
    },
    table() {
      return localStorage.getItem("table") == null ? {} : this.selected_table;
    },
    category() {
      return this.selected_category_data;
    },
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
  },
  methods: {
    saveResource() {
      this.v$.formData.NAME.$touch();
      this.v$.formData.PRICE.$touch();
      this.v$.formData.GROUP_ID.$touch();
      this.v$.formData.BUY_PRICE.$touch();
      this.v$.formData.STOCK_AMOUNT.$touch();
      console.log(this.formData);
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      MenuItemsService.store(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            // Clear modals
            $("#product-add-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();
            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getMenuItemsByGroup(this.selected_group);
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          toast.error(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getMenuItemSize() {
      return MenuItemSizeService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.menu_item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    toggleCategory(val) {
      this.add_category = val;
      if (val) {
        setTimeout(() => {
          this.$refs.add_category_key.focus();
        }, 400);
      }
    },
    addCategory() {
      this.add_category = false;
      let data = {
        NAME: this.formData.category_name,
        order_type_id: this.order_type.ID,
      };
      MenuCategoryService.storeSingle(data).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Category created!");
            this.formData = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
          }
          this.getMenuCategoryByOrder();
          this.$store.commit("SET_LOADING", true);
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          toast.error("The name already exist!");
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    toggleGroup(val) {
      this.add_group = val;
      if (val) {
        setTimeout(() => {
          this.$refs.add_group_key.focus();
        }, 400);
      }
    },
    addGroup() {
      this.$store.commit("SET_LOADING", true);
      this.add_group = false;
      let data = {
        NAME: this.formData.group_name,
        order_type_id: this.order_type.ID,
        category_id: this.selected_category,
      };
      MenuGroupService.storeSingle(data).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Group created!");
            this.formData = {};
            // get groups
            this.getMenuGroupsByCategory();
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
          }
          this.getMenuGroupsByOrder();
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          console.log(error.response.data.message);
          this.$store.commit("SET_LOADING", false);
          toast.error("The name already exist!");
        }
      );
    },
    deleteItem(item) {
      this.cart.splice(this.cart.indexOf(item), 1);
    },
    editItem(item, quantity) {
      if (quantity == 0) {
        this.cart.splice(this.cart.indexOf(item), 1);
      }
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.ID == item.ID);
        existingElement.QUANTITY = quantity;
        existingElement.SUB_TOTAL = quantity * item.PRICE;
      }
      this.cart = myCart;
      toast.success("1 " + item.NAME + " updated in cart");
    },
    truncateText(str, n) {
      return str.length > n ? str.substr(0, n - 1) + "..." : str;
    },
    toggleCheckout(val) {
      this.checkout = val;
    },
    formatToCurrency: function (amount) {
      return amount.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
    },
    sortItems(list) {
      return list.sort((a, b) => (a.NAME > b.NAME ? 1 : -1));
    },
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    tenderedInput() {
      // this.formData.balance_amount =  this.formData.tender_amount;
      this.formData.balance_amount =
        parseFloat(this.formData.tender_amount) - parseFloat(this.totalValue);
    },
    payMpesa() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "MPESA";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    payCash() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      // Custome for cash
      this.formData.settled = 1;
      this.formData.payment_method = "CASH";
      // End
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Sale placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    completeCheckout() {
      this.preview_cash_modal = false;
      this.preview_dc_modal = false;
      this.digital_change = false;
      this.change_sent = false;
      this.qrData = {};
      this.clearCart();
      this.$notify({
        group: "foo",
        type: "success",
        title: "Checkout complete",
        text: "order places and paid successfuly!",
      });
    },
    getGroups(cat) {
      console.log(cat);
      this.selected_category_data = cat;
      this.selected_category = cat.ID;
      if (this.selected_category == null) {
        this.getItemsByOrder(this.order_type);
      } else {
        this.getMenuGroupsByCategory();
      }
      // this.getMenuItemsByGroup(this.groups[0].ID);
    },
    getMenuGroupsByCategory() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        menu_category: this.selected_category,
      };
      return ProductService.getMenuGroupsByCategory(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
            console.log(response.data.data);
            this.selected_group = response.data.data.at(0) ? response.data.data.at(0).ID : "";
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    filterItems() {
      this.searchKey = this.searchKey.toLowerCase();
      if (this.searchKey) {
        let array = Object.values(this.items);
        this.items = array.filter((item) => {
          let name = item.NAME.toLowerCase();
          return name.includes(this.searchKey);
        });
      } else {
        this.getAll();
      }
    },
    orderTicket() {
      console.log(this.cartLength);
      if (this.cartLength == "empty") {
        toast.error("No item selected, please add items to cart!");
        return;
      }
      this.$store.commit("SET_LOADING", true);
      this.formData.cart = this.cart;
      this.formData.table_id = this.selected_table.ID;
      this.formData.total_amount = this.totalValue;
      this.formData.order_type_id = this.order_type.ID;
      this.formData.order_type_name = this.order_type.NAME;
      TicketService.order(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            toast.success("Order placed!");
            this.cart = {};
          } else {
            console.log(response.data.error);
            toast.error("Error placing order!");
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    clearCart() {
      this.cart = {};
      toast.info("Cart cleared!");
    },
    goToMyOrders() {
      this.$router.push("/my-orders");
    },
    goToAllOrders() {
      this.$router.push("/pos-open-order");
    },
    handleLogout() {
      this.$store.dispatch("auth/logout");
      this.$router.push("/login");
    },
    getItemsByOrder(item) {
      return CartService.byOrder(item.ID).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAll() {
      return CartService.getAll().then(
        (response) => {
          if (response.data.code == 200) {
            this.items = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuItemsByGroup(id) {
      this.$store.commit("SET_LOADING", true);
      this.selected_group = id;
      let formData = {
        menu_group: this.selected_group,
      };
      return ProductService.getMenuItemsByGroup(formData).then(
        (response) => {
          if (response.data.code == 200) {
            this.items = this.sortItems(response.data.data);
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getAllTables() {
      return TablesService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.tables = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllOrderTypes() {
      return OrderTypeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.order_types = response.data.data;
            this.loading = false;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuGroupsByOrder() {
      let formData = {
        order_type: this.order_type.ID,
      };
      return ProductService.getMenuGroupsByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.groups = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategoryByOrder() {
      this.$store.commit("SET_LOADING", true);
      let formData = {
        order_type: this.order_type.ID,
      };
      return ProductService.getMenuCategoryByOrder(formData).then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getCustomers() {
      // samdoh
      return CustomerService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.customers = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllProducts() {
      return ProductService.getAll().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getAllGroups() {
      return ProductService.index().then(
        (response) => {
          if (response.data.status == "success") {
            this.items = response.data.data;
          } else {
            console.log(response.data.error);
          }
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
        }
      );
    },
    getMenuCategories() {
      this.$store.commit("SET_LOADING", true);
      return ProductService.getMenuCategories().then(
        (response) => {
          if (response.data.status == "success") {
            this.productCategories = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          if (error.response.status == 401) {
            this.handleLogout();
          }
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    addTocart(item) {
      console.log("Adding to cart...");
      // this.$store.dispatch("pos/addToCart", item);
      let myCart = this.cart ? Object.values(this.cart) : [];
      if (myCart.includes(item)) {
        let existingElement = myCart.find((element) => element.ID == item.ID);
        item.SUB_TOTAL = item.QUANTITY * item.PRICE;
        existingElement.QUANTITY = existingElement.QUANTITY + 1;
        existingElement.SUB_TOTAL =
          existingElement.SUB_TOTAL + item.QUANTITY * item.PRICE;
      } else {
        item.QUANTITY = 1;
        item.SUB_TOTAL = item.QUANTITY * item.PRICE;
        myCart.push(item);
      }
      this.cart = myCart;
      toast.info("1 " + item.NAME + " added to cart!", {
        autoClose: 2000,
      });
    },
    selectTable(item) {
      this.table_selection = false;
      localStorage.setItem("table", JSON.stringify(item));
      this.$notify({
        group: "foo",
        type: "info",
        title: "Selection",
        text: "Table selected!",
      });
    },
    async selectOrderType(item) {
      this.$store.commit("SET_LOADING", true);
      this.order_type = item;
      this.table_selection = true;
      this.order_type_selection = false;
      localStorage.setItem("order_type", JSON.stringify(item));
      //   toast.success(item.NAME + " selected!");
      await this.getItemsByOrder(item);
      await this.getMenuGroupsByOrder();
      await this.getMenuCategoryByOrder();
      this.$store.commit("SET_LOADING", false);
    },
  },
};
</script>
