// Data sevice
import axios from "axios";
import authHeader from "./auth-header";

const VUE_APP_BASE_URL = process.env.VUE_APP_BASE_URL;

class MenuItemsService {
  index() {
    return axios.get(VUE_APP_BASE_URL + "menu-item", { headers: authHeader() });
  }
  inventoryTrans() {
    return axios.get(VUE_APP_BASE_URL + "menu-item-transactions", {
      headers: authHeader(),
    });
  }
  store(item, config) {
    return axios.post(VUE_APP_BASE_URL + "menu-item", item, config);
  }
  update(item, config) {
    return axios.put(VUE_APP_BASE_URL + "menu-item/" + item.ID, item, config);
  }
  delete(item) {
    return axios.delete(VUE_APP_BASE_URL + "menu-item/" + item.ID, {
      headers: authHeader(),
    });
  }
  reduce(item) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-reduce", item, {
      headers: authHeader(),
    });
  }
  restock(item) {
    return axios.post(VUE_APP_BASE_URL + "menu-item-restock", item, {
      headers: authHeader(),
    });
  }
}

export default new MenuItemsService();
