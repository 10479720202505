import { createRouter, createWebHashHistory } from "vue-router";
import Login from "../views/LoginView.vue";
import PosView from "../views/PosView.vue";
import ShopView from "../views/ShopView.vue";
import BlankView from "../views/BlankView.vue";
import RoomsView from "../views/RoomsView.vue";
import ModulesView from "../views/ModulesView.vue";
import MenuItemView from "../views/MenuItemView.vue";
import MenuGroupView from "../views/MenuGroupView.vue";
import ResetPasswordView from "../views/ResetPasswordView.vue";

import CustomersView from "../views/CustomersView.vue";
import DashboardView from "../views/DashboardView.vue";
import OpenOrdersView from "../views/OpenOrdersView.vue";
import SalesReportView from "../views/SalesReportView.vue";
import MenuItemSizeView from "../views/MenuItemSizeView.vue";
import MenuCategoryView from "../views/MenuCategoryView.vue";
import MenuEasyEditView from "../views/MenuEasyEditView.vue";
import ForgotPasswordView from "../views/ForgotPasswordView.vue";
import PaymentAnalysisView from "../views/PaymentAnalysisView.vue";
import InventoryDashboardView from "../views/InventoryDashboardView.vue";
import TicketAnalysisReportView from "../views/TicketAnalysisReportView.vue";
import SalesReportOrderwiseView from "../views/SalesReportOrderwiseView.vue";
import OrderTypesView from "../views/OrderTypesView.vue";



const routes = [
  {
    path: "/shop",
    name: "shop",
    component: ShopView,
  },
  
  {
    path: "/order_type.index",
    name: "order_type.index",
    component: OrderTypesView,
  },
  {
    path: "/pos",
    name: "pod",
    component: PosView,
  },
  {
    path: "/rooms",
    name: "Rooms",
    component: RoomsView,
  },
  {
    path: "/rooms",
    name: "Rooms",
    component: RoomsView,
  },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordView,
  },
  {
    path: "/reset-password/:token",
    name: "reset-password",
    component: ResetPasswordView,
  },
  
  {
    path: "/customer.index",
    name: "customer.index",
    component: CustomersView,
  },
  {
    path: "/menu_category.index",
    name: "menu_category.index",
    component: MenuCategoryView,
  },
  {
    path: "/menu_group.index",
    name: "menu_group.index",
    component: MenuGroupView,
  },
  {
    path: "/menu_item_size.index",
    name: "menu_item_size.index",
    component: MenuItemSizeView,
  },
  
  {
    path: "/menu_item.index",
    name: "menu_item",
    component: MenuItemView,
  },
  {
    path: "/menu-easy-edit",
    name: "menu-easy-edit",
    component: MenuEasyEditView,
  },

  //
  {
    path: "/daily-sales-report-by-order",
    name: "daily-sales-report-by-order",
    component: SalesReportOrderwiseView,
  },

  {
    path: "/inventory.index",
    name: "inventory-dash",
    component: InventoryDashboardView,
  },
  {
    path: "/home",
    name: "home",
    component: PosView,
  },
  {
    path: "/blank",
    name: "blank",
    component: BlankView,
  },
  {
    path: "/login",
    name: "login",
    component: Login,
  },
  {
    path: "/",
    name: "init",
    component: Login,
  },
  {
    path: "/module.index",
    name: "module.index",
    component: ModulesView,
  },
  {
    path: "/pos-open-order",
    name: "pos-open-order",
    component: OpenOrdersView,
  },

  {
    path: "/dashboard",
    name: "dashboard",
    component: DashboardView,
  },
  {
    path: "/sales-analysis-report",
    name: "sales-analysis-report",
    component: SalesReportView,
  },
  {
    path: "/ticket-analysis",
    name: "ticket_analysis",
    component: TicketAnalysisReportView,
  },
  {
    path: "/payment-analysis-report",
    name: "payment_analysis_report",
    component: PaymentAnalysisView,
  },
];

const router = createRouter({
  mode: "history",
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  base: process.env.VUE_APP_ROOT_URL,
  history: createWebHashHistory(process.env.VUE_APP_ROOT_URL),
  routes: routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  },
});

// control auth in all routes
router.beforeEach((to, from, next) => {
  const publicPages = [
    "/login",
    "/register/enterprise",
    "/register/individual",
    "/register/starter",
    "/register/standard",
    "/home",
  ];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem("token");
  // trying to access a restricted page + not logged in
  // redirect to login page
  if (authRequired && !loggedIn) {
    next("/login");
  } else {
    next();
  }
});

export default router;
