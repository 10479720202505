<template>
  <div>
    <LoaderOverlay v-if="loading" />
    <!-- ===============================================-->
    <!--    Main Content-->
    <!-- ===============================================-->
    <main class="main" id="top">
      <div class="container" data-layout="container">
        <div class="row flex-center min-vh-100 py-6">
          <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
            <div class="card">
              <div class="card-header">
                <a
                  class="d-flex flex-center mt-3"
                  href="https://www.jia.co.ke/"
                >
                  <img
                    class="me-2"
                    src="assets/img/jia.png"
                    alt=""
                    height="48"
                  />
                </a>
                <!-- <span class="font-sans-serif fs-2 d-flex flex-center">Restautant</span> -->
              </div>

              <div class="position-relative">
                <hr />
                <div class="divider-content-center">
                  <h5>Forgot you password?</h5>
                </div>
              </div>
              <div class="card-body">
                <div class="text-center mb-3">
                  <small
                    >Enter your email and we'll send you a reset link.</small
                  >
                </div>
                <div class="input-group mb-3">
                  <span class="input-group-text" id="basic-addon1">
                    <i class="fas fa-envelope me-2 ms-2 text-primary"></i>
                  </span>
                  <input
                    v-model="formData.email"
                    class="form-control input-lg"
                    type="email"
                    placeholder="Email address"
                    aria-label="Email address"
                    aria-describedby="basic-addon1"
                    v-on:keydown.enter="handleReset"
                    :class="
                      v$.formData.email.$dirty && v$.formData.email.$invalid
                        ? `is-invalid`
                        : v$.formData.email.$dirty
                        ? `is-valid`
                        : ``
                    "
                  />
                  <div
                    v-for="error of v$.formData.email.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! The email address is invalid.
                  </div>
                </div>
                <div class="mb-3">
                  <button
                    class="btn btn-primary d-block w-100 mt-3"
                    @click="handleReset"
                  >
                    Send reset link
                  </button>
                </div>
                <div class="position-relative mt-4">
                  <hr />
                  <div class="divider-content-center">
                    <span class="font-sans-serif d-flex flex-center"
                      >Insurance BI |
                      <a class="mb-0 text-500">Version {{ version }}</a></span
                    >
                    <!-- <span class="mb-0 undefined">or</span>
                    <span><a href="register.html">Create an account</a></span> -->
                  </div>
                </div>
                <div class="row g-2 mt-4">
                  <small class="text-muted d-flex flex-center mt-2">
                    {{ year }} &copy; Bulla Softworks Ltd</small
                  >
                  <a
                    class="d-flex flex-center mt-n1"
                    href="https://bulla.co.ke"
                  >
                    <img
                      class="me-2"
                      src="assets/img/icons/spot-illustrations/bulla.png"
                      alt=""
                      height="32"
                    />
                  </a>
                </div>

                <!-- <div class="row g-2 mt-2">
                <div class="col-sm-6"><a class="btn btn-outline-google-plus btn-sm d-block w-100"
                    href="login.html#"><span class="fab fa-google-plus-g me-2" data-fa-transform="grow-8"></span>
                    google</a></div>
                <div class="col-sm-6"><a class="btn btn-outline-facebook btn-sm d-block w-100" href="login.html#"><span
                      class="fab fa-facebook-square me-2" data-fa-transform="grow-8"></span> facebook</a></div>
              </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
    <!-- ===============================================-->
    <!--    End of Main Content-->
    <!-- ===============================================-->
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import mixins from "../mixins/index";
import { useVuelidate } from "@vuelidate/core";
import UserService from "../services/user.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  name: "LoginView",
  mixins: [mixins],
  setup() {
    return { v$: useVuelidate() };
  },
  components: {
    LoaderOverlay,
  },
  data() {
    return {
      year: new Date().getFullYear(),
      formData: {
        email: "",
        os: this.detectOS(),
        browser: this.detectBrowser(),
      },
      users: [],
      version: process.env.VUE_APP_VERSION,
    };
  },
  validations() {
    return {
      formData: {
        email: { required, email },
      },
    };
  },
  created() {
    this.getAllUsers();
  },
  mounted() {
    // Theme setup custome to bellalisa
    localStorage.setItem("navbarStyle", "card");
    localStorage.setItem("isFluid", true);
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  methods: {
    setUser(email) {
      console.log(email);
      this.username_key = email;
      this.formData.email = email;
    },
    gotToRegister() {
      this.$router.push("/register");
    },
    gotToForgotPassword() {
      this.$router.push("/forgot-password");
    },
    getAllUsers() {
      this.$store.commit("SET_LOADING", true);
      return UserService.getAllUsers().then(
        (response) => {
          console.log(response.data);
          if (response.data.code == 200) {
            this.$store.commit("SET_LOADING", false);
            this.users = response.data.data;
          } else {
            console.log(response.data.error);
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          toast.error(
            error.response.data.message ||
              (error.response && error.response.data) ||
              error.mesage ||
              error.toString(),
            {
              autoClose: 3000,
            }
          );
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    handleReset() {
      this.v$.formData.email.$touch();
      if (this.v$.formData.$errors.length > 0) {
        window.scrollTo(0, 0);
        return;
      }
      this.$store.commit("SET_LOADING", true);
      UserService.reset(this.formData).then(
        (response) => {
          if (response.data.status == 200) {
            toast.success(response.data.message);
            this.$store.commit("SET_LOADING", false);
            setTimeout(() => {
              this.$router.push("/login");
            }, 2000);
          } else {
            toast.error(response.data.message);
            this.$store.commit("SET_LOADING", false);
          }
        },
        (error) => {
          if (error.response.status == 422) {
            console.log("customer");
            toast.error(error.response.data.message);
            this.$store.commit("SET_LOADING", false);
          } else if (error.response.status == 502) {
            toast.info(error.response.data.message);
            this.$store.commit("SET_LOADING", false);
          } else {
            toast.error(error.response.data.message);
            this.$store.commit("SET_LOADING", false);
          }
        }
      );
      window.scrollTo(0, 0);
    },
  },
};
</script>
