<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />
      <div class="row g-3 mb-3">
        <div class="col-12 col-xl-12">
          <div class="row g-3">
            <div class="col-12">
              <div class="card bg-transparent-50 overflow-hidden">
                <div class="card-header position-relative">
                  <div
                    class="bg-holder d-none d-md-block bg-card z-index-1"
                    style="
                      background-image: url('../assets/img/illustrations/ecommerce-bg.png');
                      background-size: 230px;
                      background-position: right bottom;
                      z-index: -1;
                    "
                  ></div>
                  <!--/.bg-holder-->
                  <div class="position-relative z-index-2">
                    <div>
                      <h5 class="text-primary mb-1">
                        {{ getTimegreetings() }}, {{ user.FIRST_NAME }}!
                      </h5>
                      <p>Here’s what happening with your store today</p>
                    </div>

                    <div class="d-flex py-3">
                      <button
                        class="btn btn-warning"
                        type="button"
                        data-bs-toggle="modal"
                        data-bs-target="#register-usage-modal"
                      >
                        Register Usage
                      </button>

                      <div class="ps-3 mx-3">
                        <button
                          class="btn btn-success"
                          data-bs-toggle="modal"
                          data-bs-target="#restock-modal"
                        >
                          Restock
                        </button>
                      </div>
                      <!-- <div class="ps-3">
                        <button class="btn btn-outline-info">Add New</button>
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <div class="accordion" id="accordionExample">
                    <div class="accordion-item rounded-0">
                      <ul class="mb-0 list-unstyled">
                        <li
                          class="accordion-header alert mb-0 rounded-0 py-3 px-card alert-danger border-x-0 border-top-0"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse1"
                          aria-expanded="true"
                          aria-controls="collapse1"
                        >
                          <div class="row flex-between-center">
                            <div class="col">
                              <div class="d-flex">
                                <div class="fas fa-circle mt-1 fs--2"></div>
                                <p class="fs--1 ps-2 mb-0">
                                  <strong>15 products</strong> are below the
                                  replenish/refill level
                                </p>
                              </div>
                            </div>
                            <div class="col-auto d-flex align-items-center">
                              <a
                                class="alert-link fs--1 fw-medium"
                                href="e-commerce.html#!"
                                >View products<i
                                  class="fas fa-chevron-right ms-1 fs--2"
                                ></i
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div
                        class="accordion-collapse collapse"
                        id="collapse1"
                        aria-labelledby="heading1"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <span class="badge rounded-pill badge-soft-primary"
                            >coming soon</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <ul class="mb-0 list-unstyled">
                        <li
                          id="heading2"
                          class="accordion-header alert mb-0 rounded-0 py-3 px-card alert-success border-x-0 border-top-0"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse2"
                          aria-expanded="true"
                          aria-controls="collapse2"
                        >
                          <div class="row flex-between-center">
                            <div class="col">
                              <div class="d-flex">
                                <div
                                  class="fas fa-circle mt-1 fs--2 text-primary"
                                ></div>
                                <p class="fs--1 ps-2 mb-0">
                                  <strong>7 Products </strong> have been
                                  replenished today!
                                </p>
                              </div>
                            </div>
                            <div class="col-auto d-flex align-items-center">
                              <a
                                class="alert-link fs--1 fw-medium"
                                href="e-commerce.html#!"
                                >View products<i
                                  class="fas fa-chevron-right ms-1 fs--2"
                                ></i
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>
                      <div
                        class="accordion-collapse collapse"
                        id="collapse2"
                        aria-labelledby="heading2"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <span class="badge rounded-pill badge-soft-primary"
                            >coming soon</span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <ul class="mb-n1 list-unstyled">
                        <li
                          id="heading3"
                          class="accordion-header alert mb-0 rounded-0 py-3 px-card alert-secondary border-x-0 border-top-0"
                          data-bs-toggle="collapse"
                          data-bs-target="#collapse3"
                          aria-expanded="true"
                          aria-controls="collapse3"
                        >
                          <div class="row flex-between-center">
                            <div class="col">
                              <div class="d-flex">
                                <div
                                  class="fas fa-circle mt-1 fs--2 text-primary"
                                ></div>
                                <p class="fs--1 ps-2 mb-0">
                                  <strong
                                    >{{ inventory_trans.length }}+ products
                                  </strong>
                                  have been used today
                                </p>
                              </div>
                            </div>
                            <div class="col-auto d-flex align-items-center">
                              <a
                                class="alert-link fs--1 fw-medium"
                                href="e-commerce.html#!"
                                >View orders<i
                                  class="fas fa-chevron-right ms-1 fs--2"
                                ></i
                              ></a>
                            </div>
                          </div>
                        </li>
                      </ul>

                      <div
                        class="accordion-collapse collapse"
                        id="collapse3"
                        aria-labelledby="heading3"
                        data-bs-parent="#accordionExample"
                      >
                        <div class="accordion-body">
                          <!-- <h5>Used products</h5> -->
                          <!-- Menu items table  -->
                          <EasyDataTable
                            id="tour-step-1"
                            class="mx-0 my-0"
                            table-class-name="customize-table"
                            alternating
                            buttons-pagination
                            :headers="headers_used"
                            :items="inventory_trans"
                            :loading="loading"
                            @click-row="showRow"
                          >
                            <template #loading>
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </template>
                            <template #item-id="item">
                              <a class="text-secondary ms-2">
                                #<strong class="ml-1">{{ item.id }}</strong></a
                              >
                            </template>
                            <template #item-name="item">
                              <strong> {{ item.product.NAME }}</strong>
                            </template>
                            <template #item-owner="item">
                              <strong>{{ item.owner.FIRST_NAME }}</strong>
                              {{ item.owner.LAST_NAME }}
                            </template>
                            <template #item-size="item">
                              <div
                                class="address py-2 align-middle white-space-nowrap"
                              >
                                <p class="mb-0 text-900">
                                  {{ item.size.title }}
                                </p>
                              </div>
                            </template>
                            <template #item-created_at="item">
                              <div
                                class="address py-2 align-middle white-space-nowrap"
                              >
                                <p class="mb-0 text-900">
                                  {{ formatDate(item.created_at) }}
                                </p>
                              </div>
                            </template>

                            <template #item-action="item">
                              <div
                                class="dropdown font-sans-serif position-static"
                              >
                                <button
                                  class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                                  type="button"
                                  id="order-dropdown-0"
                                  data-bs-toggle="dropdown"
                                  data-boundary="viewport"
                                  aria-haspopup="true"
                                  aria-expanded="false"
                                >
                                  <span class="fas fa-ellipsis-h fs--1"></span>
                                </button>
                                <div
                                  class="dropdown-menu dropdown-menu-end border py-0"
                                  aria-labelledby="order-dropdown-0"
                                >
                                  <div class="py-2">
                                    <a
                                      class="dropdown-item"
                                      type="button"
                                      data-bs-toggle="modal"
                                      :data-bs-target="
                                        '#staticBackdrop' + item.ID
                                      "
                                    >
                                      Preview
                                    </a>
                                    <div class="dropdown-divider"></div>
                                    <a
                                      class="dropdown-item text-danger"
                                      role="button"
                                      @click="voidTicket(item)"
                                      >Delete</a
                                    >
                                  </div>
                                </div>
                              </div>
                            </template>
                            <template #item-TOTAL_PRICE="item">
                              <div
                                class="align-middle text-right white-space-nowrap"
                              >
                                <p class="mb-0 fw-bold text-900">
                                  {{ formatToCurrency(item.TOTAL_PRICE) }}
                                </p>
                              </div>
                            </template>
                          </EasyDataTable>
                          <!-- End Menu items table  -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-12">
              <div class="card mb-3" id="ordersTable">
                <div class="card-header">
                  <div class="row flex-between-center">
                    <div
                      class="col-4 col-sm-auto d-flex align-items-center pe-0"
                    >
                      <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">
                        Inventory Products
                      </h5>
                    </div>
                    <div class="col-8 col-sm-auto ms-auto text-end ps-0 d-flex">
                      <div class="search-box">
                        <input
                          class="form-control search-input fuzzy-search"
                          type="text"
                          placeholder="Search..."
                          aria-label="Search"
                          v-model="searchValue"
                        />
                        <span class="fas fa-search search-box-icon"></span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-body p-0">
                  <!-- Menu items table  -->
                  <EasyDataTable
                    id="tour-step-1"
                    class="mx-2 my-2"
                    table-class-name="customize-table"
                    alternating
                    buttons-pagination
                    :headers="headers"
                    :items="menu_items"
                    :search-field="searchField"
                    :search-value="searchValue"
                    :loading="loading"
                  >
                    <template #loading>
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </template>
                    <template #item-ID="item">
                      <div
                        class="form-check fs-0 mb-0 d-flex align-items-center"
                      >
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="checkbox-0"
                          :value="item.ID"
                          @click="updateSelected"
                          data-bulk-select-row="data-bulk-select-row"
                        />
                        <a class="text-secondary ms-2">
                          #<strong class="ml-1">{{ item.ID }}</strong></a
                        >
                      </div>
                    </template>
                    <template #item-NAME="item">
                      <strong> {{ item.NAME }}</strong>
                    </template>
                    <template #item-owner="item">
                      <strong>{{ item.owner.FIRST_NAME }}</strong>
                      {{ item.owner.LAST_NAME }}
                    </template>
                    <template #item-VISIBLE="item">
                      <span
                        v-if="item.VISIBLE"
                        class="badge py-2 badge rounded-pill badge-soft-success"
                        >Active Menu Item<span
                          class="ms-1 fas fa-check"
                          data-fa-transform="shrink-2"
                        ></span
                      ></span>
                      <span
                        v-if="!item.VISIBLE"
                        class="badge py-2 badge rounded-pill badge-soft-danger"
                      >
                        Inventoy Item
                        <span
                          class="ms-1 fas fa-stream"
                          data-fa-transform="shrink-2"
                        >
                        </span>
                      </span>
                    </template>
                    <template #item-customer="item">
                      <div class="address py-2 align-middle white-space-nowrap">
                        {{ item.customer.FIRST_NAME }}
                        {{ item.customer.LAST_NAME }}
                        <p class="mb-0 text-500">{{ item.TICKET_TYPE }}</p>
                      </div>
                    </template>
                    <template #item-action="item">
                      <div class="dropdown font-sans-serif position-static">
                        <button
                          class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                          type="button"
                          id="order-dropdown-0"
                          data-bs-toggle="dropdown"
                          data-boundary="viewport"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <span class="fas fa-ellipsis-h fs--1"></span>
                        </button>
                        <div
                          class="dropdown-menu dropdown-menu-end border py-0"
                          aria-labelledby="order-dropdown-0"
                        >
                          <div class="py-2">
                            <a
                              class="dropdown-item"
                              type="button"
                              data-bs-toggle="modal"
                              :data-bs-target="'#staticBackdrop' + item.ID"
                            >
                              Preview
                            </a>
                            <div class="dropdown-divider"></div>
                            <a
                              class="dropdown-item text-danger"
                              role="button"
                              @click="voidTicket(item)"
                              >Delete</a
                            >
                          </div>
                        </div>
                      </div>
                    </template>
                    <template #item-TOTAL_PRICE="item">
                      <div class="align-middle text-right white-space-nowrap">
                        <p class="mb-0 fw-bold text-900">
                          {{ formatToCurrency(item.TOTAL_PRICE) }}
                        </p>
                      </div>
                    </template>
                  </EasyDataTable>
                  <!-- End Menu items table  -->
                </div>
                <div class="card-footer">
                  <!-- Footer  -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <FooterComp />
    </div>
    <div
      class="modal fade"
      id="authentication-modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="authentication-modal-label"
      aria-hidden="true"
    >
      <div class="modal-dialog mt-6" role="document">
        <div class="modal-content border-0">
          <div
            class="modal-header px-5 position-relative modal-shape-header bg-shape"
          >
            <div class="position-relative z-index-1 light">
              <h4 class="mb-0 text-white" id="authentication-modal-label">
                Register
              </h4>
              <p class="fs--1 mb-0 text-white">
                Please create your free Falcon account
              </p>
            </div>
            <button
              class="btn-close btn-close-white position-absolute top-0 end-0 mt-2 me-2"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body py-4 px-5">
            <form>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-name">Name</label
                ><input
                  class="form-control"
                  type="text"
                  autocomplete="on"
                  id="modal-auth-name"
                />
              </div>
              <div class="mb-3">
                <label class="form-label" for="modal-auth-email"
                  >Email address</label
                ><input
                  class="form-control"
                  type="email"
                  autocomplete="on"
                  id="modal-auth-email"
                />
              </div>
              <div class="row gx-2">
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-password"
                    >Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-password"
                  />
                </div>
                <div class="mb-3 col-sm-6">
                  <label class="form-label" for="modal-auth-confirm-password"
                    >Confirm Password</label
                  ><input
                    class="form-control"
                    type="password"
                    autocomplete="on"
                    id="modal-auth-confirm-password"
                  />
                </div>
              </div>
              <div class="form-check">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="modal-auth-register-checkbox"
                /><label class="form-label" for="modal-auth-register-checkbox"
                  >I accept the <a href="index.html#!">terms </a>and
                  <a href="index.html#!">privacy policy</a></label
                >
              </div>
              <div class="mb-3">
                <button
                  class="btn btn-primary d-block w-100 mt-3"
                  type="submit"
                  name="submit"
                >
                  Register
                </button>
              </div>
            </form>
            <div class="position-relative mt-5">
              <hr />
              <div class="divider-content-center">or register with</div>
            </div>
            <div class="row g-2 mt-2">
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-google-plus btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-google-plus-g me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  google</a
                >
              </div>
              <div class="col-sm-6">
                <a
                  class="btn btn-outline-facebook btn-sm d-block w-100"
                  href="index.html#"
                  ><span
                    class="fab fa-facebook-square me-2"
                    data-fa-transform="grow-8"
                  ></span>
                  facebook</a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Start new usage modal  -->
    <div
      class="modal fade"
      id="register-usage-modal"
      :key="modals_key"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-2 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">Add a new usage</h4>
            </div>
            <div class="px-4 pb-0">
              <form>
                <div class="mb-3">
                  <label class="col-form-label" for="recipient-name"
                    >Product Name:</label
                  >
                  <div
                    class="form-control"
                    id="recipient-name"
                    type="text"
                    :class="
                      v$.formData.product_id.$dirty &&
                      v$.formData.product_id.$invalid
                        ? `is-invalid`
                        : v$.formData.product_id.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <select
                      ref="choicejs1"
                      class="form-select bg-danger is-invalid"
                      id="organizerSingle"
                      size="1"
                      name="organizerSingle"
                      data-options='{"removeItemButton":true,"placeholder":true}'
                      @change="itemSelected"
                    >
                      <option value="">Select product...</option>
                    </select>
                  </div>
                  <div
                    v-for="error of v$.formData.product_id.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! This field is invalid.
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <label class="col-form-label" for="recipient-name"
                      >Quantity:</label
                    >
                    <input
                      class="form-control"
                      id="recipient-name"
                      type="text"
                      v-model="formData.quantity"
                      :class="
                        v$.formData.quantity.$dirty &&
                        v$.formData.quantity.$invalid
                          ? `is-invalid`
                          : v$.formData.quantity.$dirty
                          ? `is-valid`
                          : ``
                      "
                    />
                    <div
                      v-for="error of v$.formData.quantity.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label" for="recipient-name"
                      >Size:</label
                    >
                    <select
                      class="form-select"
                      aria-label="Select size"
                      @change="sizeSelected"
                      :class="
                        v$.formData.size_id.$dirty &&
                        v$.formData.size_id.$invalid
                          ? `is-invalid`
                          : v$.formData.size_id.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option value="" selected>Select...</option>
                      <option
                        selected=""
                        v-for="(item, i) in item_sizes"
                        :key="i"
                        :value="item.id"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formData.size_id.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="col-form-label" for="message-text"
                    >Message:</label
                  >
                  <textarea class="form-control" id="message-text"></textarea>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer mx-2">
            <button
              class="btn btn-secondary float-left"
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              class="btn btn-warning ms-auto"
              type="button"
              @click="reduceStock"
            >
              Reduce stock
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End new usage modal  -->

    <!-- Start restock modal  -->
    <div
      class="modal fade"
      id="restock-modal"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-dialog-centered"
        role="document"
        style="max-width: 500px"
      >
        <div class="modal-content position-relative">
          <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
            <button
              class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body p-0">
            <div class="rounded-top-lg py-2 ps-4 pe-6 bg-light">
              <h4 class="mb-1" id="modalExampleDemoLabel">Add a new usage</h4>
            </div>
            <div class="px-4 pb-0">
              <form>
                <div class="mb-3">
                  <label class="col-form-label" for="recipient-name"
                    >Product Name:</label
                  >
                  <div
                    class="form-control"
                    id="recipient-name"
                    type="text"
                    :class="
                      v$.formData.product_id.$dirty &&
                      v$.formData.product_id.$invalid
                        ? `is-invalid`
                        : v$.formData.product_id.$dirty
                        ? `is-valid`
                        : ``
                    "
                  >
                    <select
                      ref="choicejs2"
                      class="form-select bg-danger is-invalid"
                      id="organizerSingle"
                      size="1"
                      name="organizerSingle"
                      data-options='{"removeItemButton":true,"placeholder":true}'
                      @change="itemSelected"
                    >
                      <option value="">Select product...</option>
                    </select>
                  </div>
                  <div
                    v-for="error of v$.formData.product_id.$errors"
                    :key="error.$uid"
                    class="invalid-feedback"
                    style="color: red"
                  >
                    Oops! This field is invalid.
                  </div>
                </div>
                <div class="row mb-3">
                  <div class="col-6">
                    <label class="col-form-label" for="recipient-name"
                      >Quantity:</label
                    >
                    <input
                      class="form-control"
                      id="recipient-name"
                      type="text"
                      v-model="formData.quantity"
                      :class="
                        v$.formData.quantity.$dirty &&
                        v$.formData.quantity.$invalid
                          ? `is-invalid`
                          : v$.formData.quantity.$dirty
                          ? `is-valid`
                          : ``
                      "
                    />
                    <div
                      v-for="error of v$.formData.quantity.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                  <div class="col-6">
                    <label class="col-form-label" for="recipient-name"
                      >Size:</label
                    >
                    <select
                      class="form-select"
                      aria-label="Select size"
                      @change="sizeSelected"
                      :class="
                        v$.formData.size_id.$dirty &&
                        v$.formData.size_id.$invalid
                          ? `is-invalid`
                          : v$.formData.size_id.$dirty
                          ? `is-valid`
                          : ``
                      "
                    >
                      <option value="" selected>Select...</option>
                      <option
                        v-for="(item, i) in item_sizes"
                        :key="i"
                        :value="item.id"
                      >
                        {{ item.title }}
                      </option>
                    </select>
                    <div
                      v-for="error of v$.formData.size_id.$errors"
                      :key="error.$uid"
                      class="invalid-feedback"
                      style="color: red"
                    >
                      Oops! This field is invalid.
                    </div>
                  </div>
                </div>
                <div class="mb-3">
                  <label class="col-form-label" for="message-text"
                    >Message:</label
                  >
                  <textarea class="form-control" id="message-text"></textarea>
                </div>
              </form>
            </div>
          </div>
          <div class="modal-footer mx-2">
            <button
              class="btn btn-secondary float-left"
              type="button"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              class="btn btn-success ms-auto"
              type="button"
              @click="reStock"
            >
              Restock
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- End new usage modal  -->
  </div>
</template>

<script>
import $ from "jquery";
import Choices from "choices.js";
import mixins from "../mixins/index";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import CountUp from "vue-countup-v3";
import AppBar from "@/components/AppBar.vue";
import EasyDataTable from "vue3-easy-data-table";
import NavDrawer from "@/components/NavDrawer.vue";
import SimpleTypeahead from "vue3-simple-typeahead";
import FooterComp from "@/components/FooterComp.vue";
import ReportService from "../services/report.service";
import ItemSizeService from "../services/item_size.service";
import MenuItemsService from "../services/menu_item.service";

import { useVuelidate } from "@vuelidate/core";
import { required, email, minLength } from "@vuelidate/validators";

export default {
  name: "InventoryDashboardView",
  mixins: [mixins],
  components: {
    AppBar,
    CountUp,
    NavDrawer,
    FooterComp,
    EasyDataTable,
    SimpleTypeahead,
  },
  data() {
    return {
      searchValue: "",
      modals_key: 1,
      item_name: "",
      menu_items: [],
      item_sizes: [],
      selected_items: [],
      inventory_trans: [],
      units: [
        {
          id: 1,
          name: "Pieces",
        },
      ],
      refreshed: 0,
      daily_analysis: [],
      weekly_analysis: [],
      formData: {
        user_id: "",
        quantity: "",
        product_id: "",
        size_id: "",
      },
      searchField: ["ID", "NAME", "DESCRIPTION", "UNIT_NAME"],
      headers: [
        { text: "ITEM #", value: "ID", sortable: true },
        { text: "NAME", value: "NAME", sortable: true },
        { text: "DESCRIPTION", value: "DESCRIPTION", sortable: true },
        {
          text: "STOCK AMOUNT",
          value: "STOCK_AMOUNT",
          sortable: true,
        },
        { text: "UNIT NAME", value: "UNIT_NAME", sortable: true },
        { text: "VISIBLE", value: "VISIBLE", sortable: true },
        { text: "Action", value: "action" },
      ],
      headers_used: [
        { text: "TRANS #", value: "id", sortable: true },
        { text: "PRODUCT NAME", value: "name", sortable: true },
        { text: "USER", value: "owner", sortable: true },
        {
          text: "QUANTITY",
          value: "quantity",
          sortable: true,
        },
        { text: "UOM", value: "size", sortable: true },
        { text: "NOTE", value: "note", sortable: true },
        { text: "TRANS DATE", value: "created_at", sortable: true },
        { text: "Action", value: "action" },
      ],
      menu_item_names: [],
    };
  },
  validations() {
    return {
      formData: {
        size_id: { required },
        product_id: { required },
        quantity: { required },
        user_id: { required },
      },
    };
  },
  created() {
    this.getItemSize();
    this.getInventoryTrans();
    // this.getWeeklyAnalysis();
    // this.getDailyAnalysis();
  },
  async mounted() {
    // Start create modal choice
    console.group("choices");
    await this.getMenuItems();
    let choices = this.menu_items.map((el) => {
      return { id: el.ID, value: el.NAME };
    });
    var elm1 = this.$refs.choicejs1;
    var init = new Choices(elm1, {
      choices: choices,
    });
    var elm2 = this.$refs.choicejs2;
    var init = new Choices(elm2, {
      choices: choices,
    });
    // End create modal choice

    var isFluid = JSON.parse(localStorage.getItem("isFluid"));
    if (isFluid) {
      var container = document.querySelector("[data-layout]");
      container.classList.remove("container");
      container.classList.add("container-fluid");
    }
    var navbarStyle = localStorage.getItem("navbarStyle");
    if (navbarStyle && navbarStyle !== "transparent") {
      document
        .querySelector(".navbar-vertical")
        .classList.add(`navbar-${navbarStyle}`);
    }
    var navbarPosition = localStorage.getItem("navbarPosition");
    var navbarVertical = document.querySelector(".navbar-vertical");
    var navbarTopVertical = document.querySelector(".content .navbar-top");
    var navbarTop = document.querySelector(
      "[data-layout] .navbar-top:not([data-double-top-nav"
    );
    var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
    var navbarTopCombo = document.querySelector(
      '.content [data-navbar-top="combo"]'
    );

    if (localStorage.getItem("navbarPosition") === "double-top") {
      document.documentElement.classList.toggle("double-top-nav-layout");
    }

    if (navbarPosition === "top") {
      navbarTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTopCombo.remove(navbarTopCombo);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "combo") {
      navbarVertical.removeAttribute("style");
      navbarTopCombo.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarTopVertical.remove(navbarTopVertical);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "double-top") {
      navbarDoubleTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTop.remove(navbarTop);
      navbarTopCombo.remove(navbarTopCombo);
    } else {
      navbarVertical.removeAttribute("style");
      navbarTopVertical.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarDoubleTop.remove(navbarDoubleTop);
      navbarTopCombo.remove(navbarTopCombo);
    }
  },
  setup() {
    return { v$: useVuelidate() };
  },
  computed: {
    user() {
      return JSON.parse(localStorage.getItem("user"));
    },
    menu_item_names() {
      return this.menu_items.map((el) => el.NAME);
    },
    selected_item() {
      return this.menu_items.find((el) => el.NAME == this.item_name);
    },
  },
  watch: {
    menu_items: {
      hendler(newVal, oldVal) {},
      deep: true,
    },
  },
  methods: {
    updateSelected(e) {
      let item_id = event.target.value;
      if (event.target.checked) {
        this.selected.push(item_id);
      } else {
        this.selected = this.selected.filter((el) => el != item_id);
      }
      this.selected_items = this.selected.join(",");
    },
    reStock() {
      console.log(this.selected_item);
      this.formData.user_id = this.user.AUTO_ID;
      this.formData.product_id = this.selected_item
        ? this.selected_item.ID
        : "";
      console.log(this.formData);
      this.v$.formData.$touch();
      if (this.v$.formData.$errors.length > 0) {
        return;
      }
      return MenuItemsService.restock(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Item updated successfully!");
            // Clear modals
            $("#restock-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();
            this.selected_item = {};
            this.formData = {};
            this.v$.formData.$reset();
            this.getMenuItems();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    reduceStock() {
      console.log(this.selected_item);
      this.formData.user_id = this.user.AUTO_ID;
      this.formData.product_id = this.selected_item
        ? this.selected_item.ID
        : "";
      console.log(this.formData);
      this.v$.formData.$touch();
      if (this.v$.formData.$errors.length > 0) {
        return;
      }
      return MenuItemsService.reduce(this.formData).then(
        (response) => {
          if (response.data.code == 200) {
            toast.success("Item updated successfully!");
            // Clear modals
            $("#register-usage-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();
            this.selected_item = {};
            this.formData = {};
            this.v$.formData.$reset();
            this.getMenuItems();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    itemSelected(e) {
      this.item_name = e.target.value;
      this.formData.product_id = this.menu_items.find(
        (el) => el.NAME == this.item_name
      ).ID;
      console.log(e.target.value);
      this.v$.formData.product_id.$touch();
    },
    sizeSelected(e) {
      this.formData.size_id = e.target.value;
    },
    goToCustomers() {},
    getTimegreetings() {
      const time = new Date().getHours();
      let greetings;
      if (time < 12) greetings = "Good Morning";
      else if (time >= 12 && time <= 17) greetings = "Good Afternoon";
      else greetings = "Good Evening";
      return greetings;
    },
    formatToCurrency(amount) {
      if (amount || amount == 0) {
        const internationalNumberFormat = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "KES",
        });
        return internationalNumberFormat.format(amount);
      } else {
        return amount;
      }
    },

    getInventoryTrans() {
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.inventoryTrans().then(
        (response) => {
          if (response.data.code == 200) {
            this.inventory_trans = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getMenuItems() {
      this.$store.commit("SET_LOADING", true);
      return MenuItemsService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.menu_items = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },

    getItemSize() {
      this.$store.commit("SET_LOADING", true);
      return ItemSizeService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.item_sizes = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getDailyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.dailyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.daily_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    getWeeklyAnalysis() {
      this.$store.commit("SET_LOADING", true);
      return ReportService.weeklyAnalysis().then(
        (response) => {
          if (response.data.code == 200) {
            this.weekly_analysis = response.data.data;
          } else {
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
