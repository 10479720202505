<template>
  <div class="container" data-layout="container">
    <NavDrawer />
    <div class="content">
      <AppBar />
      <div
        class="card mb-3"
        id="ordersTable"
        data-list='{"valueNames":["order","date","address","status","amount"],"page":10,"pagination":true}'
      >
        <div class="card-header">
          <div class="row flex-between-center border-bottom pb-1">
            <div class="col-4 col-sm-auto d-flex align-items-center pe-0">
              <h5 class="fs-0 mb-0 text-nowrap py-2 py-xl-0">Modules</h5>
            </div>
            <div class="col-8 col-sm-auto ms-auto text-end ps-0">
              <div class="d-none" id="orders-bulk-actions">
                <div class="d-flex">
                  <select
                    class="form-select form-select-sm"
                    aria-label="Bulk actions"
                  >
                    <option selected="">Bulk actions</option>
                    <option value="Refund">Refund</option>
                    <option value="Delete">Delete</option>
                    <option value="Archive">Archive</option></select
                  ><button
                    class="btn btn-falcon-default btn-sm ms-2"
                    type="button"
                  >
                    Apply
                  </button>
                </div>
              </div>
              <div id="orders-actions" class="d-flex">
                <div class="search-box fs--1">
                  <input
                    class="form-control search-input fuzzy-search h1"
                    type="text"
                    placeholder="Search..."
                    aria-label="Search"
                    v-model="searchValue"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </div>
                <button
                  class="btn btn-falcon-warning btn-sm mx-2 h1"
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#add-modal"
                >
                  <span class="fas fa-plus" data-fa-transform=""></span
                  ><span class="d-none d-sm-inline-block ms-1">New</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="card-body px-3 py-0">
          <!-- Menu items table  -->
          <EasyDataTable
            id="tour-step-1"
            table-class-name="customize-table"
            alternating
            buttons-pagination
            :headers="headers"
            :items="resources"
            :search-field="searchField"
            :search-value="searchValue"
            :loading="loading"
          >
            <template #loading>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </template>
            <template #item-id="item">
              <div class="form-check fs-0 mb-0 d-flex align-items-center">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id="checkbox-0"
                  :value="item.id"
                  @click="updateSelected"
                  data-bulk-select-row="data-bulk-select-row"
                />
                <a class="text-secondary ms-2">
                  #<strong class="ml-1">{{ item.id }}</strong></a
                >
              </div>
            </template>
            <template #item-title="item">
              <div class="my-2">
                <strong>{{ item.title }}</strong>
                <p class="mb-0 text-500 text-wrap">{{ item.detail }}</p>
              </div>
            </template>
            <template #item-action="item">
              <div class="dropdown font-sans-serif position-static">
                <button
                  class="btn btn-link text-600 btn-sm dropdown-toggle btn-reveal"
                  type="button"
                  id="order-dropdown-0"
                  data-bs-toggle="dropdown"
                  data-boundary="viewport"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <span class="fas fa-ellipsis-h fs--1"></span>
                </button>
                <div
                  class="dropdown-menu dropdown-menu-end border py-0"
                  aria-labelledby="order-dropdown-0"
                >
                  <div class="py-2">
                    <a
                      class="dropdown-item"
                      role="button"
                      data-bs-toggle="modal"
                      :data-bs-target="'#edit-modal' + item.id"
                      >Edit</a
                    >

                    <div class="dropdown-divider"></div>
                    <a
                      class="dropdown-item text-danger"
                      role="button"
                      @click="deleteRecource(item)"
                      >Delete</a
                    >
                  </div>
                </div>
              </div>
            </template>
          </EasyDataTable>
          <!-- End Menu items table  -->
        </div>
        <div class="card-footer">
          <!-- footer content  -->
        </div>
      </div>
      <FooterComp />

      <div v-for="(resource, i) in resources" :key="i">
        <!-- Start edit modal  -->
        <div
          class="modal fade"
          :id="'edit-modal' + resource.id"
          data-bs-keyboard="false"
          data-bs-backdrop="static"
          tabindex="-1"
          :aria-labelledby="'edit-modal' + resource.id + 'Label'"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg mt-6" role="document">
            <div class="modal-content border-0">
              <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
                <button
                  class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body p-0">
                <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                  <h4 class="mb-1" id="staticBackdropLabel">Edit module</h4>
                </div>
                <div class="p-4">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-9 col-xl-9">
                      <div class="d-flex">
                        <span class="fa-stack ms-n1 me-3"
                          ><i class="fas fa-circle fa-stack-2x text-200"></i
                          ><i
                            class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                            data-fa-transform="shrink-2"
                          ></i
                        ></span>
                        <div class="flex-1">
                          <div class="row">
                            <div
                              class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                            >
                              <label class="col-form-label" for="recipient-name"
                                >Name:</label
                              >
                              <input
                                class="form-control"
                                id="recipient-name"
                                type="text"
                                :placeholder="resource.title"
                                v-model="formData.title"
                                :class="
                                  v$.formData.title.$dirty &&
                                  v$.formData.title.$invalid
                                    ? `is-invalid`
                                    : v$.formData.title.$dirty
                                    ? `is-valid`
                                    : ``
                                "
                              />
                              <div
                                v-for="error of v$.formData.title.$errors"
                                :key="error.$uid"
                                class="invalid-feedback"
                                style="color: red"
                              >
                                This field is invalid
                              </div>
                            </div>
                            <div
                              class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                            >
                              <label class="col-form-label" for="message-text"
                                >Icon:</label
                              >
                              <input
                                class="form-control"
                                id="recipient-name"
                                type="text"
                                :placeholder="resource.icon"
                                v-model="formData.icon"
                              />
                            </div>
                            <div
                              class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                            >
                              <label class="col-form-label" for="message-text"
                                >Color:</label
                              >
                              <input
                                class="form-control"
                                id="recipient-name"
                                type="text"
                                :placeholder="resource.color"
                                v-model="formData.color"
                              />
                            </div>
                          </div>
                          <div class="row">
                            <div
                              class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                            >
                              <label class="col-form-label" for="recipient-name"
                                >Order:</label
                              >
                              <input
                                class="form-control"
                                id="recipient-name"
                                type="text"
                                :placeholder="resource.order"
                                v-model="formData.order"
                              />
                            </div>
                            <div
                              class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                            >
                              <label class="col-form-label" for="message-text"
                                >Route:</label
                              >
                              <input
                                class="form-control"
                                id="recipient-name"
                                type="text"
                                :placeholder="resource.link"
                                v-model="formData.link"
                              />
                            </div>
                            <div
                              class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                            >
                              <label class="col-form-label" for="message-text"
                                >Select parent?</label
                              >
                              <select
                                class="form-select"
                                placeholder="Select parent"
                                v-model="formData.id_parent"
                              >
                                <option
                                  v-for="mod in resources"
                                  :key="mod.id"
                                  :value="mod.id"
                                  :selected="mod.id == resource.id_parent"
                                >
                                  {{ mod.title }}
                                </option>
                              </select>
                            </div>
                          </div>
                          <hr class="my-4" />
                        </div>
                      </div>
                      <div class="d-flex">
                        <span class="fa-stack ms-n1 me-3"
                          ><i class="fas fa-circle fa-stack-2x text-200"></i
                          ><i
                            class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                            data-fa-transform="shrink-2"
                          ></i
                        ></span>
                        <div class="flex-1">
                          <h5 class="mb-2 fs-0">Description</h5>

                          <p class="text-word-break fs--1">
                            <textarea
                              v-model="formData.detail"
                              class="form-control"
                              id="exampleFormControlTextarea1"
                              rows="3"
                              :placeholder="formData.detail"
                            ></textarea>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                      <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                      <ul class="nav flex-lg-column fs--1">
                        <li class="nav-item me-2 me-lg-0">
                          <a
                            @click="toggleActive"
                            class="nav-link nav-link-card-details"
                            role="button"
                            :class="
                              formData.active || resource.active
                                ? `bg-success text-white`
                                : ``
                            "
                            ><span class="fa fa-check me-2"></span>Active?
                          </a>
                        </li>
                        <li class="nav-item me-2 me-lg-0">
                          <a
                            @click="toggleRoot"
                            class="nav-link nav-link-card-details"
                            role="button"
                            :class="
                              formData.is_root || resource.is_root
                                ? `bg-success text-white`
                                : ``
                            "
                          >
                            <span class="fas fa-user me-2"></span>Root/Parent?
                          </a>
                        </li>
                        <li class="nav-item me-2 me-lg-0">
                          <a
                            class="nav-link nav-link-card-details"
                            role="button"
                            @click="toggleShowOnMain"
                            :class="
                              formData.show_on_main_page ||
                              resource.show_on_main_page
                                ? `bg-success text-white`
                                : ``
                            "
                            ><span class="fas fa-align-left me-2"></span>Show on
                            Drawer</a
                          >
                        </li>
                        <li class="nav-item me-2 me-lg-0 mt-8">
                          <a
                            class="nav-link nav-link-card-details bg-danger text-white"
                            role="button"
                            @click="deleteRecource(resource)"
                            ><span class="fas fa-paperclip me-2"></span
                            >Delete</a
                          >
                        </li>
                        <li class="nav-item me-2 me-lg-0 mt-2">
                          <a
                            class="nav-link nav-link-card-details bg-primary text-white"
                            role="button"
                            @click="updateResource(resource)"
                            ><span class="fa fa-align-left me-2"></span>Update
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End edit modal  -->
      </div>

      <!-- Start add modal  -->
      <div
        class="modal fade"
        id="add-modal"
        data-bs-keyboard="false"
        data-bs-backdrop="static"
        tabindex="-1"
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div class="modal-dialog modal-lg mt-6" role="document">
          <div class="modal-content border-0">
            <div class="position-absolute top-0 end-0 mt-3 me-3 z-index-1">
              <button
                class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body p-0">
              <div class="bg-light rounded-top-lg py-3 ps-4 pe-6">
                <h4 class="mb-1" id="staticBackdropLabel">Add new module</h4>
              </div>
              <div class="p-4">
                <div class="row">
                  <div class="col-xs-12 col-sm-12 col-lg-9 col-xl-9">
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i class="fas fa-circle fa-stack-2x text-200"></i
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-tag"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <div class="row">
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="recipient-name"
                              >Name:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.g Dashboard"
                              v-model="formData.title"
                              :class="
                                v$.formData.title.$dirty &&
                                v$.formData.title.$invalid
                                  ? `is-invalid`
                                  : v$.formData.title.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.title.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div class="mb3 col-sm-12 col-md-6 col-lg-4 col-xl-4">
                            <label class="col-form-label" for="message-text"
                              >Icon:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.g fa fa-user"
                              v-model="formData.icon"
                            />
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Color:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.g #FFFF"
                              v-model="formData.color"
                            />
                          </div>
                        </div>
                        <div class="row">
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="recipient-name"
                              >Order:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.g 777"
                              v-model="formData.order"
                            />
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Route:</label
                            >
                            <input
                              class="form-control"
                              id="recipient-name"
                              type="text"
                              placeholder="e.g /kitchen"
                              v-model="formData.link"
                              :class="
                                v$.formData.link.$dirty &&
                                v$.formData.link.$invalid
                                  ? `is-invalid`
                                  : v$.formData.link.$dirty
                                  ? `is-valid`
                                  : ``
                              "
                            />
                            <div
                              v-for="error of v$.formData.link.$errors"
                              :key="error.$uid"
                              class="invalid-feedback"
                              style="color: red"
                            >
                              This field is invalid
                            </div>
                          </div>
                          <div
                            class="mb-3 col-sm-12 col-md-6 col-lg-4 col-xl-4"
                          >
                            <label class="col-form-label" for="message-text"
                              >Select parent?</label
                            >
                            <select
                              class="form-select"
                              placeholder="Select parent"
                              v-model="formData.id_parent"
                            >
                              <option
                                v-for="mod in resources"
                                :key="mod.id"
                                :value="mod.id"
                                :selected="mod.id == formData.id_parent"
                              >
                                {{ mod.title }}
                              </option>
                            </select>
                          </div>
                        </div>
                        <hr class="my-4" />
                      </div>
                    </div>
                    <div class="d-flex">
                      <span class="fa-stack ms-n1 me-3"
                        ><i class="fas fa-circle fa-stack-2x text-200"></i
                        ><i
                          class="fa-inverse fa-stack-1x text-primary fas fa-align-left"
                          data-fa-transform="shrink-2"
                        ></i
                      ></span>
                      <div class="flex-1">
                        <h5 class="mb-2 fs-0">Description</h5>

                        <p class="text-word-break fs--1">
                          <textarea
                            v-model="formData.detail"
                            class="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                            placeholder="Add details here..."
                          ></textarea>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-3 col-xl-3">
                    <h6 class="mt-5 mt-lg-0">Other Actions</h6>
                    <ul class="nav flex-lg-column fs--1">
                      <li class="nav-item me-2 me-lg-0">
                        <a
                          @click="toggleActive"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="formData.active ? `bg-info text-white` : ``"
                          ><span class="fa fa-check me-2"></span>Active?
                        </a>
                      </li>
                      <li class="nav-item me-2 me-lg-0">
                        <a
                          @click="toggleRoot"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="formData.is_root ? `bg-info text-white` : ``"
                        >
                          <span class="fas fa-user me-2"></span>Root/Parent?
                        </a>
                      </li>
                      <li class="nav-item me-2 me-lg-0">
                        <a
                          @click="toggleShowOnMain"
                          class="nav-link nav-link-card-details"
                          role="button"
                          :class="
                            formData.show_on_main_page
                              ? `bg-info text-white`
                              : ``
                          "
                          ><span class="fas fa-align-left me-2"></span>Show on
                          Drawer</a
                        >
                      </li>
                      <br />
                      <hr />
                      <li class="nav-item me-2 me-lg-0 mt-auto">
                        <a
                          class="nav-link nav-link-card-details bg-success text-white"
                          role="button"
                          @click="saveResource"
                          ><span class="fa fa-check me-2"></span>Save
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- End add modal  -->
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import AppBar from "@/components/AppBar.vue";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import NavDrawer from "@/components/NavDrawer.vue";
import FooterComp from "@/components/FooterComp.vue";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import ModuleService from "../services/module.service";
import LoaderOverlay from "@/components/LoaderOverlay.vue";
import { useVuelidate } from "@vuelidate/core";
import { required, email } from "@vuelidate/validators";
import EasyDataTable from "vue3-easy-data-table";

const now = new Date();

export default {
  name: "TicketAnalysisReportView",
  setup() {
    return { v$: useVuelidate() };
  },
  validations() {
    return {
      formData: {
        title: { required },
        link: { required },
      },
    };
  },
  components: {
    NavDrawer,
    AppBar,
    flatPickr,
    FooterComp,
    LoaderOverlay,
    EasyDataTable,
  },
  data() {
    return {
      searchValue: "",
      table_keys: 1,
      formData: {
        is_root: 0,
        active: 0,
        show_on_main_page: 0,
      },
      resources: [],
      orders_table_key: 0,
      searchField: ["title", "icon", "detail", "route", "link"],
      headers: [
        { text: "ITEM #", value: "id", sortable: true },
        { text: "NAME", value: "title", sortable: true },
        { text: "ICON", value: "icon", sortable: true },
        { text: "ROUTE", value: "route", sortable: true },
        { text: "LINK", value: "link", sortable: true },
        { text: "Action", value: "action" },
      ],
    };
  },
  created() {
    this.getData();
  },
  watch: {
    resources(newVal, oldVal) {
      console.log("resource length", newVal.length);
      this.orders_table_key += 1;
    },
  },
  computed: {
    loading() {
      return this.$store.getters.getLoading;
    },
  },
  mounted() {
    this.user = JSON.parse(localStorage.getItem("user"));
    console.log("Loading..");
    var isFluid = JSON.parse(localStorage.getItem("isFluid"));
    if (isFluid) {
      var container = document.querySelector("[data-layout]");
      container.classList.remove("container");
      container.classList.add("container-fluid");
    }
    var navbarStyle = localStorage.getItem("navbarStyle");
    if (navbarStyle && navbarStyle !== "transparent") {
      document
        .querySelector(".navbar-vertical")
        .classList.add(`navbar-${navbarStyle}`);
    }
    var navbarPosition = localStorage.getItem("navbarPosition");
    var navbarVertical = document.querySelector(".navbar-vertical");
    var navbarTopVertical = document.querySelector(".content .navbar-top");
    var navbarTop = document.querySelector(
      "[data-layout] .navbar-top:not([data-double-top-nav"
    );
    var navbarDoubleTop = document.querySelector("[data-double-top-nav]");
    var navbarTopCombo = document.querySelector(
      '.content [data-navbar-top="combo"]'
    );

    if (localStorage.getItem("navbarPosition") === "double-top") {
      document.documentElement.classList.toggle("double-top-nav-layout");
    }

    if (navbarPosition === "top") {
      navbarTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTopCombo.remove(navbarTopCombo);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "combo") {
      navbarVertical.removeAttribute("style");
      navbarTopCombo.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarTopVertical.remove(navbarTopVertical);
      navbarDoubleTop.remove(navbarDoubleTop);
    } else if (navbarPosition === "double-top") {
      navbarDoubleTop.removeAttribute("style");
      navbarTopVertical.remove(navbarTopVertical);
      navbarVertical.remove(navbarVertical);
      navbarTop.remove(navbarTop);
      navbarTopCombo.remove(navbarTopCombo);
    } else {
      navbarVertical.removeAttribute("style");
      navbarTopVertical.removeAttribute("style");
      navbarTop.remove(navbarTop);
      navbarDoubleTop.remove(navbarDoubleTop);
      navbarTopCombo.remove(navbarTopCombo);
    }
  },
  methods: {
    toggleShowOnMain() {
      this.formData.show_on_main_page = !this.formData.show_on_main_page;
    },
    toggleActive() {
      this.formData.active = !this.formData.active;
    },
    toggleRoot() {
      this.formData.is_root = !this.formData.is_root;
    },
    saveResource() {
      this.v$.formData.title.$touch();
      this.v$.formData.link.$touch();
      if (this.v$.formData.$invalid) {
        return;
      }
      this.$store.commit("SET_LOADING", true);
      ModuleService.store(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            // Clear modals
            $("#add-modal").hide();
            $("body").removeClass("modal-open");
            $(".modal-backdrop").remove();

            this.table_keys += 1;
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
          } else {
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    updateResource(resource) {
      this.$store.commit("SET_LOADING", true);
      this.formData.id = resource.id;
      if (!this.formData.icon) {
        this.formData.icon = resource.icon;
      }
      if (!this.formData.title) {
        this.formData.title = resource.title;
      }
      if (!this.formData.color) {
        this.formData.color = resource.color;
      }
      if (!this.formData.detail) {
        this.formData.detail = resource.detail;
      }
      if (!this.formData.order) {
        this.formData.order = resource.order;
      }
      if (!this.formData.link) {
        this.formData.link = resource.link;
      }
      if (!this.formData.id_parent) {
        this.formData.id_parent = resource.id_parent;
      }
      if (!this.formData.is_root) {
        this.formData.is_root = resource.is_root;
      }
      if (!this.formData.active) {
        this.formData.active = resource.active;
      }
      if (!this.formData.show_on_main_page) {
        this.formData.show_on_main_page = resource.show_on_main_page;
      }
      ModuleService.update(this.formData).then(
        (response) => {
          console.log(response);
          if (response.data.code == 200) {
            toast.success(response.data.message, {
              autoClose: 2000,
            });
            this.getData();
            // Clear modals
            $("#edit-modal" + resource.id)
              .removeClass("fade")
              .hide();
            $(".modal-backdrop").remove();
          } else {
            this.resources = [];
            console.log(response.data.error);
            toast.error(response.data.error, {
              autoClose: 2000,
            });
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
    deleteRecource(resource) {
      this.$store.commit("SET_LOADING", true);
      let sure = confirm(
        "Are you sure you want to delete? you cannot undo this action."
      );
      if (sure) {
        ModuleService.delete(resource.id).then(
          (response) => {
            console.log(response);
            if (response.data.code == 200) {
              toast.success(response.data.message, {
                autoClose: 2000,
              });
              this.getData();
              $("#edit-modal" + resource.id)
                .removeClass("fade")
                .hide();
              $(".modal-backdrop").remove();
            } else {
              console.log(response.data.error);
              toast.error(response.data.error, {
                autoClose: 2000,
              });
            }
            this.$store.commit("SET_LOADING", false);
          },
          (error) => {
            console.log(error);
            this.$store.commit("SET_LOADING", false);
          }
        );
      }
    },
    getData() {
      this.$store.commit("SET_LOADING", true);
      return ModuleService.index().then(
        (response) => {
          if (response.data.code == 200) {
            this.resources = response.data.data;
          } else {
            this.resources = [];
            console.log(response.data.error);
          }
          this.$store.commit("SET_LOADING", false);
        },
        (error) => {
          console.log(error);
          this.$store.commit("SET_LOADING", false);
        }
      );
    },
  },
};
</script>
